import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useSearchTabAvailability = () => {
  const { search } = useLocation();

  const isGoogleScholarTab = useMemo(
    () => new URLSearchParams(search).get('fs') === 'gs',
    [search]
  );

  const isWebTab = useMemo(
    () => new URLSearchParams(search).get('fs') === 'g',
    [search]
  );

  const isRnDTab = useMemo(
    () => !isGoogleScholarTab && !isWebTab,
    [isGoogleScholarTab, isWebTab]
  );

  return {
    isGoogleScholarTab,
    isRnDTab,
    isWebTab,
  };
};
