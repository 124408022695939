import React, { FC } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import { FormSectionDivider } from 'common/components/FormSection/FormSectionDivider';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { ControlledTooltip } from 'common/components/Tooltips/ControlledTooltip';
import { WarningTooltip } from 'common/components/Tooltips/WarningTooltip';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { Nullable } from 'common/utils/assert';

import TagsAutocomplete from '../../../Tagging/TagsAutocomplete';
import { PrivateDocForm } from '../../PrivateDocForm/PrivateDocForm';
import { isSharedTag } from '../../privateDocs.utils';

import { AddPrivateDocsFormValues } from './AddPrivateDocsFormValues.interface';

interface Props extends FormikProps<AddPrivateDocsFormValues> {
  loadingsStates: Array<Nullable<'loading' | 'success' | 'error'>>;
}

export const AddPrivateDocForm: FC<Props> = ({
  loadingsStates,
  ...helpers
}) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const { isShareToTeamInDoc } = useTenantFeatures();

  const sharedTags = helpers.values.selectedTags
    .filter(isSharedTag)
    .map(({ name }) => name);

  const isShareTooltip = !helpers.values.shareWithOrg && !!sharedTags.length;

  return (
    <Form>
      <DialogContent>
        <DialogContentText>
          <MarkdownText content={t('privateDocs:addDialog.explanation')} />
        </DialogContentText>

        <PrivateDocForm loadingsStates={loadingsStates} {...helpers} />

        <FormSectionDivider />

        <TagsAutocomplete
          helperText={t('tags:tagsAutocomplete.helperText')}
          label={t('tags:tagsAutocomplete.label')}
          margin="normal"
          name="selectedTags"
          suggestedTags={[]}
        />
        <FormGroup>
          <FormControlLabel
            control={<FormSwitchControl name="addToFavorites" />}
            label={t('privateDocs:form.addToFavorites.label')}
            labelPlacement="start"
          />
          {isShareToTeamInDoc && (
            <FormControlLabel
              control={
                <ControlledTooltip
                  Component={WarningTooltip}
                  open={isShareTooltip}
                  placement="left"
                  title={t('privateDocs:addPrivateDocForm.errorTags', {
                    tags: sharedTags.join(', '),
                  })}
                  arrow
                >
                  <FormSwitchControl name="shareWithOrg" />
                </ControlledTooltip>
              }
              label={t('privateDocs:form.sharing.label')}
              labelPlacement="start"
            />
          )}
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Grid justifyContent="flex-end" spacing={2} container>
          <Grid item>
            <Button disabled={helpers.isSubmitting} type="reset">
              {t('common:buttons.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <SubmitButton
              disabled={helpers.isSubmitting}
              eventName={TrackEventName.PrivateDocumentImported}
              isSubmitting={helpers.isSubmitting}
              text={t('privateDocs:form.submitButton.text')}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Form>
  );
};
