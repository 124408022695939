import React, { useMemo } from 'react';

import { Typography } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import { TruncatedContent } from 'common/components/Content/TruncatedContent/TruncatedContent';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import {
  getPaperTextMaxLength,
  mergeHighlightAndAbstract,
} from 'common/utils/documents';
import { FeedbackType } from 'containers/Feedback/enums';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { DocCardComposition } from 'containers/User/User.enum';

export type DocAbstractProps = {
  abstractContent: string;
  cardComposition?: DocCardComposition;
  collapseByDefault?: boolean;
  highlight?: string;
  id?: string;
  resultType?: ResultType;
  searchId?: string;
};

const DocAbstract = React.memo(
  ({
    abstractContent,
    cardComposition,
    collapseByDefault = true,
    highlight,
    id,
    resultType,
    searchId,
  }: DocAbstractProps) => {
    const text = useMemo(
      () => mergeHighlightAndAbstract(abstractContent, highlight),
      [abstractContent, highlight]
    );
    const maxTextLength = getPaperTextMaxLength(highlight, cardComposition);
    const { sendFeedback } = useSendFeedback();

    const handleAbstractFeedback = () => {
      if (searchId && resultType) {
        sendFeedback({
          feedbackType: FeedbackType.Abstract,
          resultId: id ?? 'unknown',
          resultType,
          searchId,
        });
      }
    };

    return (
      <Typography
        component="div"
        sx={{
          '& em': {
            fontStyle: 'normal',
            fontWeight: 'fontWeightBold',
          },
          '& p': {
            display: 'inherit',
            margin: 0,
          },
          overflowWrap: 'anywhere',
          wordWrap: 'break-word',
        }}
        variant="body2"
      >
        <TruncatedContent
          collapseByDefault={collapseByDefault}
          eventName={TrackEventName.SeeMoreClicked}
          maxTextLength={maxTextLength}
          text={text}
          renderMarkdown
          onCollapse={handleAbstractFeedback}
        />
      </Typography>
    );
  }
);

DocAbstract.displayName = 'DocAbstract';

export default DocAbstract;
