import React, { FC } from 'react';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import { StyledBadge } from 'common/components/Badges/StyledBadge';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';

import { NotificationsContent } from './NotificationsContent/NotificationsContent';
import { useNotifications } from './useNotifications';

export const Notifications: FC = () => {
  const theme = useTheme<AppTheme>();
  const { t } = useTranslation('common');
  const {
    anchorEl,
    changelogUpdates,
    hasUpdates,
    onPopoverClose,
    onPopoverOpen,
    updatesCount,
  } = useNotifications();

  if (!hasUpdates) return null;

  return (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Notifications toolbar' }}
    >
      <Tooltip title={t<string>('navBar.notifications.tooltipTitle')}>
        <IconButton
          color="contrast"
          data-testid="Notifications-IconButton"
          size="large"
          onClick={onPopoverOpen}
        >
          <StyledBadge badgeContent={updatesCount} color="error" max={99}>
            <NotificationsActiveIcon
              sx={{ color: theme.palette.header.iconColor }}
            />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <NotificationsContent
        anchorEl={anchorEl}
        changelogUpdates={changelogUpdates}
        onClose={onPopoverClose}
      />
    </TrackedActionContext.Provider>
  );
};
