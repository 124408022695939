import { uniq } from 'lodash';

import {
  AuthenticationSettings,
  BotConfiguration,
  ChatFeatureConfiguration,
  ClientIndexSettings,
  ClientIndexSettingsTypeEnum,
  FeatureSettings,
  PDFViewer,
  QAConfiguration,
  SearchFilterSettings,
  SearchSortingSettings,
  TagsSection,
  TenantSettings as TenantSettingsResponse,
  WhiteLabelPagesSettings,
  WhiteLabelSettings,
  WidgetsConfiguration,
} from '@zarn/vendor/dist/search';

import { RetrievalUnitEnum } from 'common/enums';
import { Nullable } from 'common/utils/assert';
import { PartialRecord } from 'common/utils/types.helpers';

import { deserializeDisplayConfiguration } from './displayConfiguration.utils';
import {
  deserializeDynamicFilters,
  isNotDynamicFilter,
} from './dynamicFilters.utils';
import {
  BotConfiguration as BotsConfigurationOutput,
  BotType,
  ChatSettings,
  DefaultBotTypes,
  FeaturesSettings,
  Oidc,
  SearchFilters,
  SearchSortingSettings as SearchSorting,
  Tab,
  Tabs,
  TenantSettings,
  Widgets,
} from './tenantSettingsApi.types';
import {
  InfoPage,
  InfoPagesKeys,
  WhitelabelSettings as WhitelabelSettingsOutput,
} from './WhitelabelSettings.types';
import { deserializeWidgetsLayout } from './WidgetLayout/WidgetLayout.utils';

const validateBotType = (botType: BotType, chatSettings: ChatSettings) => {
  return (
    chatSettings?.botCapabilities.includes(botType) &&
    !!chatSettings.botsConfiguration[botType]
  );
};

export const getTenantBotType = (
  botType: BotType,
  chatSettings: ChatSettings
): string => {
  if (!validateBotType(botType, chatSettings)) {
    throw new Error(`BotType ${botType} is not support`);
  }

  return chatSettings.botsConfiguration[botType]!.tenantBotType;
};

export const isChatAvailableForTenant = (chatSettings: ChatSettings) =>
  !!chatSettings.defaultBot &&
  chatSettings.botCapabilities.includes(chatSettings.defaultBot) &&
  !!chatSettings.botsConfiguration[chatSettings.defaultBot];

export const getBotsModes = ({ botCapabilities }: TenantSettings['chat']) =>
  botCapabilities;

const deserializeFilterEnabled = (
  filterName: string,
  filtersArr: Array<SearchFilterSettings>
) => {
  const isFilterInArray = filtersArr.find(
    ({ field_name }) => field_name === filterName
  );

  return !!isFilterInArray;
};

const deserializeSearchFilters = (
  filters: ClientIndexSettings[] | null
): SearchFilters => {
  if (!filters) {
    return {
      codeFilterEnabled: false,
      countryFilterEnabled: false,
      dateFilterEnabled: false,
      organizationFilterEnabled: false,
      ownerFilterEnabled: false,
      sourceFilterEnabled: false,
      tagFilterEnabled: false,
      typeFilterEnabled: false,
    };
  }
  const defaultIndex = filters.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Internal
  );

  const filtersArr = (defaultIndex[0].search_filters_config ?? []).filter(
    isNotDynamicFilter
  );

  return {
    codeFilterEnabled: deserializeFilterEnabled(
      'metadata.github_metrics',
      filtersArr
    ),
    countryFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.creator.location.country',
      filtersArr
    ),
    dateFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.date',
      filtersArr
    ),
    organizationFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.creator.organization.name',
      filtersArr
    ),
    ownerFilterEnabled: deserializeFilterEnabled(
      'allow_access_rights',
      filtersArr
    ),
    sourceFilterEnabled: deserializeFilterEnabled(
      'metadata.DCMI.source',
      filtersArr
    ),
    tagFilterEnabled: deserializeFilterEnabled(
      'features.tags.tag_id',
      filtersArr
    ),
    typeFilterEnabled: deserializeFilterEnabled('document_type', filtersArr),
  };
};

const deserializeWidgets = (widgets: WidgetsConfiguration | null): Widgets => ({
  analyticsWidgetEnabled: !!widgets?.analytics,
  expertSearchWidgetEnabled: !!widgets?.expert_search,
  findAuthoredByWidgetEnabled: !!widgets?.find_authored_by,
  findSimilarDocumentWidgetEnabled: !!widgets?.find_similar_document,
  layout: deserializeWidgetsLayout(widgets),
  qaWidgetEnabled: !!widgets?.qa,
  queryAnalysisWidgetEnabled: !!widgets?.query_analysis,
  searchResultsWidgetEnabled: !!widgets?.search_results,
  vosViewerWidgetEnabled: !!widgets?.vos_viewer,
});

const deserializeDefaultTab = (tab: ClientIndexSettings): Tab => ({
  enabled: !!tab,
  tabTitle: tab.title ?? '',
});

const deserializeFederatedTab = (
  tab: ClientIndexSettings[],
  identifierName: string
): Tab => {
  const selectedTab = tab.find(
    (el) => el.search_engine_identifier === identifierName
  );

  return {
    enabled: !!selectedTab,
    tabTitle: selectedTab?.title ?? '',
  };
};

const deserializeTabs = (indexes: ClientIndexSettings[] | null): Tabs => {
  if (!indexes) {
    return {
      default: {
        enabled: false,
        tabTitle: '',
      },
      google: {
        enabled: false,
        tabTitle: '',
      },
      googleScholar: {
        enabled: false,
        tabTitle: '',
      },
    };
  }
  const defaultIndex = indexes.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Internal
  );
  const federatedIndex = indexes.filter(
    (el) => el.type === ClientIndexSettingsTypeEnum.Federated
  );

  return {
    default: deserializeDefaultTab(defaultIndex[0]),
    google: deserializeFederatedTab(federatedIndex, 'google'),
    googleScholar: deserializeFederatedTab(federatedIndex, 'google_scholar'),
  };
};

export const deserializeOidc = (oidc: AuthenticationSettings): Oidc => ({
  authority:
    oidc.protocol === 'oidc' && oidc.protocol_settings.oidc?.issuer
      ? oidc.protocol_settings.oidc?.issuer
      : '',
  clientId:
    oidc.protocol === 'oidc' && oidc.protocol_settings.oidc?.client_id
      ? oidc.protocol_settings.oidc?.client_id
      : '',
  enabled: oidc.protocol === 'oidc' ?? false,
});

const isBotExist = (botConfigs: Array<BotConfiguration>) => (bot: string) =>
  botConfigs.find((config) => config.bot_identifier == bot) &&
  mapBotIdentifier(bot) !== 'chat_with_pdf';

const deserializeBotsConfig = (
  botConfigs: Array<BotConfiguration>,
  extraBotIdentifiers: Nullable<string[]>,
  defaultBotIdentifier: Nullable<string>
) => {
  const defaultBotsConfiguration: PartialRecord<
    BotType,
    BotsConfigurationOutput
  > = {};
  if (!defaultBotIdentifier) {
    return {};
  }

  const defaultBotConfig = botConfigs.find(
    ({ bot_identifier }) => bot_identifier == defaultBotIdentifier
  );
  if (defaultBotConfig) {
    Object.assign(defaultBotsConfiguration, {
      [defaultBotConfig.bot_identifier]: {
        tenantBotType: defaultBotConfig.bot_type,
      },
    });
  }

  if (!extraBotIdentifiers) {
    return defaultBotsConfiguration;
  }

  const botsConfig = extraBotIdentifiers
    .filter(isBotExist(botConfigs))
    .reduce((acc, el) => {
      const foundExtraBotConfig = botConfigs.find(
        (config) => config.bot_identifier == el
      );
      return {
        ...acc,
        ...(foundExtraBotConfig
          ? {
              [el]: {
                tenantBotType: foundExtraBotConfig.bot_type,
              },
            }
          : {}),
      };
    }, defaultBotsConfiguration);

  return { ...defaultBotsConfiguration, ...botsConfig };
};

const deserializeBotsConfiguration = (
  botConfigs: Array<BotConfiguration> | null,
  chats: Array<ChatFeatureConfiguration | undefined>
): PartialRecord<BotType, BotsConfigurationOutput> => {
  if (!botConfigs) {
    return {};
  }

  return chats.reduce((acc, chat) => {
    if (!chat) {
      return acc;
    }

    const chatConfig = deserializeBotsConfig(
      botConfigs,
      chat.extra_bot_identifiers ?? null,
      chat.default_bot_identifier ?? null
    );

    return { ...acc, ...chatConfig };
  }, {} as PartialRecord<BotType, BotsConfigurationOutput>);
};

const mapBotIdentifier = (data: string): BotType => {
  switch (data) {
    case 'summary':
    case 'quizbot':
    case 'default':
    case 'chat_with_pdf':
    case 'chat_with_tag':
    case 'chat_with_qa_widget':
    case 'k_answer_table':
      return data;
    default:
      return 'chat_with_pdf';
  }
};

const deserializeBotCapabilities = (
  chats: Array<ChatFeatureConfiguration | undefined>
): BotType[] => {
  return uniq(
    chats
      .reduce(
        ([def, extra], chat) => [
          [...def, chat?.default_bot_identifier],
          [...extra, ...(chat?.extra_bot_identifiers ?? [])],
        ],
        [[], []] as Array<Array<string | undefined>>
      )
      .flat(Infinity)
      .filter(Boolean)
      .map(String)
      .map(mapBotIdentifier)
  );
};

const mapDefaultBotIdentifier = (
  data: string | undefined
): DefaultBotTypes | undefined => {
  switch (data) {
    case 'chat_with_pdf':
    case 'chat_with_tag':
    case 'chat_with_qa_widget':
    case 'k_answer_table':
      return data;
    default:
      undefined;
  }
};

export const deserializeChat = (
  chatPDF: PDFViewer | null,
  chatTag: TagsSection | null,
  chatQA: QAConfiguration | null,
  botConfig: Array<BotConfiguration> | null
): ChatSettings => {
  return {
    botCapabilities: deserializeBotCapabilities([
      chatPDF?.chat,
      chatTag?.chat,
      chatQA?.chat,
    ]),
    botsConfiguration: deserializeBotsConfiguration(botConfig, [
      chatPDF?.chat,
      chatTag?.chat,
      chatQA?.chat,
    ]),
    defaultBot: mapDefaultBotIdentifier(chatPDF?.chat?.default_bot_identifier),
    qaDefaultBot: mapDefaultBotIdentifier(chatQA?.chat?.default_bot_identifier),
    tagDefaultBot: mapDefaultBotIdentifier(
      chatTag?.chat?.default_bot_identifier
    ),
  };
};

const deserializePageContent = (
  pageContent: WhiteLabelPagesSettings,
  pageKey: InfoPagesKeys
): PartialRecord<InfoPagesKeys, InfoPage> => {
  const pageContentElement = pageContent[pageKey];
  return {
    ...(pageContent && pageContentElement
      ? {
          [pageKey]: {
            title: pageContentElement.title,
            url: pageContentElement.url,
          },
        }
      : {}),
  };
};

const deserializeWhiteLabelPages = (
  pages: WhiteLabelPagesSettings
): PartialRecord<InfoPagesKeys, InfoPage> => ({
  ...deserializePageContent(pages, 'about'),
  ...deserializePageContent(pages, 'features'),
  ...deserializePageContent(pages, 'contact'),
});

const deserializeOptionalElement = <T>(element: T, label: string) => ({
  ...(element ? { [label]: element } : {}),
});

export const deserializeWhiteLabel = (
  whitelabel: WhiteLabelSettings
): WhitelabelSettingsOutput => ({
  ...(whitelabel?.logo
    ? {
        logo: {
          mobile: whitelabel.logo.mobile,
          web: whitelabel.logo.web,
        },
      }
    : {}),
  ...(whitelabel.header
    ? {
        header: {
          ...deserializeOptionalElement(
            whitelabel.header.background,
            'background'
          ),
          ...deserializeOptionalElement(
            whitelabel.header.icon_color,
            'iconColor'
          ),
          ...deserializeOptionalElement(
            whitelabel.header.font_color,
            'fontColor'
          ),
        },
      }
    : {}),
  ...(whitelabel.search_bar
    ? {
        searchBar: {
          ...deserializeOptionalElement(
            whitelabel.search_bar.background,
            'background'
          ),
        },
      }
    : {}),
  ...(whitelabel.icons
    ? {
        icons: {
          ...deserializeOptionalElement(
            whitelabel.icons.default_color,
            'defaultColor'
          ),
          ...deserializeOptionalElement(
            whitelabel.icons.highlight,
            'highlight'
          ),
        },
      }
    : {}),
  ...(whitelabel.main_content
    ? {
        mainContent: {
          ...deserializeOptionalElement(
            whitelabel.main_content.background,
            'background'
          ),
        },
      }
    : {}),
  ...(whitelabel.pages
    ? { pages: deserializeWhiteLabelPages(whitelabel.pages) }
    : {}),
});

export const deserializeSearchSorting = (
  searchOptions: SearchSortingSettings[]
): SearchSorting => {
  const chunkSearchOptions = searchOptions
    .filter((el) => el.retrieval_unit === 'chunk' || !el.retrieval_unit)
    .map((el) => ({
      title: el.display_name,
      urlParam: el.url_param,
      value: el.field_name,
    }));
  const documentSearchOptions = searchOptions
    .filter((el) => el.retrieval_unit === 'document' || !el.retrieval_unit)
    .map((el) => ({
      title: el.display_name,
      urlParam: el.url_param,
      value: el.field_name,
    }));

  return {
    ...(chunkSearchOptions ? { chunk: chunkSearchOptions } : []),
    document: documentSearchOptions,
  };
};

const deserializeFeatures = (
  featuresSettings: FeatureSettings
): FeaturesSettings => ({
  ...(featuresSettings.people
    ? {
        people: {
          status: featuresSettings.people.status ?? 'hidden',
        },
      }
    : {}),
  ...(featuresSettings.recommendations
    ? {
        recommendations: {
          status: featuresSettings.recommendations.status ?? 'hidden',
        },
      }
    : {}),
  ...(featuresSettings.document_uploads
    ? {
        documentUploads: {
          status: featuresSettings.document_uploads.status ?? 'hidden',
        },
      }
    : {}),
});

const deserializeDefaultRetrievalUnit = (
  index: ClientIndexSettings
): RetrievalUnitEnum | undefined => {
  if (!index) return undefined;

  if (index.retrieval_unit_config?.default_retrieval_unit) {
    const retrievalUnit = index.retrieval_unit_config.default_retrieval_unit;
    const capitalizedRetrievalUnit =
      retrievalUnit.charAt(0).toUpperCase() + retrievalUnit.slice(1);

    if (capitalizedRetrievalUnit in RetrievalUnitEnum) {
      return RetrievalUnitEnum[
        capitalizedRetrievalUnit as keyof typeof RetrievalUnitEnum
      ];
    }
  }

  return undefined;
};

export const deserializeTenantSettings = (
  settings: TenantSettingsResponse,
  indexCluster?: string | null
): TenantSettings => {
  const defaultIndex = settings.client_settings?.indexes?.[0];
  const index = indexCluster
    ? settings.client_settings?.indexes?.find(
        ({ index_id }) => index_id && indexCluster.includes(index_id)
      )
    : defaultIndex ?? defaultIndex;

  const defaultRetrievalUnit = index
    ? deserializeDefaultRetrievalUnit(index)
    : undefined;

  return {
    chat: deserializeChat(
      settings.client_settings?.pdf_viewer ?? null,
      settings.client_settings?.tags_section ?? null,
      settings.client_settings?.widgets?.qa ?? null,
      settings.client_settings?.bot_configurations ?? null
    ),
    displayConfiguration: deserializeDisplayConfiguration(
      index?.display_configuration ?? null
    ),
    document: {
      ...deserializeOptionalElement(
        settings.client_settings?.document_card?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.document_card?.export_options,
        'exports'
      ),
    },
    features: settings.features ? deserializeFeatures(settings.features) : {},
    indexCluster: indexCluster ?? '',
    oidc: deserializeOidc(settings.authentication_settings),
    searchFilters: deserializeSearchFilters(
      settings.client_settings?.indexes ?? null
    ),
    searchFiltersDynamic: deserializeDynamicFilters(
      index?.search_filters_config ?? []
    ),
    ...(defaultRetrievalUnit
      ? { searchFiltersInitialValues: { retrievalUnit: defaultRetrievalUnit } }
      : {}),
    note: {
      ...deserializeOptionalElement(
        settings.client_settings?.note_card?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.note_card?.export_options,
        'exports'
      ),
    },
    tabs: deserializeTabs(settings.client_settings?.indexes ?? null),
    tag: {
      ...deserializeOptionalElement(
        settings.client_settings?.tags_section?.sharing_options,
        'sharing'
      ),
      ...deserializeOptionalElement(
        settings.client_settings?.tags_section?.export_options,
        'exports'
      ),
    },
    widgets: deserializeWidgets(settings.client_settings?.widgets ?? null),
    ...(settings.client_settings?.indexes &&
    settings.client_settings?.indexes[0].search_sorting_config
      ? {
          searchSorting: deserializeSearchSorting(
            settings.client_settings?.indexes[0].search_sorting_config
          ),
        }
      : {}),
    ...(settings.client_settings?.white_label_settings
      ? {
          whitelabel: deserializeWhiteLabel(
            settings.client_settings.white_label_settings
          ),
        }
      : {}),
  };
};
