import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ShareEnum } from 'common/enums';

import { removeSuffixFromId } from './documents';

export const NEW_LINE = '%0d%0a';
export const D_NEW_LINE = `${NEW_LINE}${NEW_LINE}`;
export const DIVIDER = `${D_NEW_LINE}------------------------------${D_NEW_LINE}`;

export const EMAIL_SUBJECT = {
  MULTI_PAPERS: 'Here are some interesting papers for you',
  SINGLE_PAPER: 'Here’s an interesting paper for you',
};

export const EMAIL_BODY = {
  MULTI_PAPERS: 'Check out this collection of papers about',
  SINGLE_PAPER: 'Check out this paper via Zeta Alpha:',
};

export type SharePaperArgs = {
  authors: string[];
  id: string;
  notes: NoteDetails[];
  shareUri?: string;
  title: string;
};

export const createDocsShareURI = (ids: string[]): string => {
  const docs = ids.map(removeSuffixFromId).join(',');
  return `${window.env.ORIGIN_URL}?doc_ids=${docs}`;
};

export const getAuthorsText = (authors: string[]): string => {
  if (!authors.length) {
    return '';
  }

  if (authors.length === 1) {
    return authors[0];
  }

  return authors.reduce((acc, cur, i) => {
    const last = i === authors.length - 1;
    if (i === 0) return cur;
    return last ? `${acc} and ${cur}` : `${acc}, ${cur}`;
  }, '');
};

export const getNotesText = (notes: NoteDetails[]): string => {
  return notes
    .filter(({ shareable }) => shareable)
    .reduce((acc, { content }, i) => {
      const formattedContent = JSON.parse(
        JSON.stringify(content).replace(/\\n/gi, NEW_LINE).toString()
      );

      return i === 0
        ? `${D_NEW_LINE}Notes: ${DIVIDER}${formattedContent}${DIVIDER}`
        : `${acc}${formattedContent}${DIVIDER}`;
    }, '');
};

export const getEmailSharePaperLink = ({
  authors,
  id,
  notes,
  shareUri,
  title,
}: SharePaperArgs): string => {
  const link = shareUri ?? createDocsShareURI([id]);
  const authorsText = getAuthorsText(authors);
  const notesText = getNotesText(notes);
  return `mailto:?subject=${EMAIL_SUBJECT.SINGLE_PAPER}&body=${EMAIL_BODY.SINGLE_PAPER}${D_NEW_LINE}"${title}" by ${authorsText}: ${notesText}${D_NEW_LINE}${link}`;
};

export const getTwitterSharePaperLink = ({
  authors,
  id,
  shareUri,
  title,
}: SharePaperArgs): string => {
  const link = shareUri ?? createDocsShareURI([id]);
  const docTitle = title.length >= 120 ? `${title.slice(0, 120)} ...` : title;
  const authorsText = authors.length > 1 ? `${authors[0]} et al` : authors[0];
  return `https://x.com/intent/tweet?text="${docTitle}" by ${authorsText}. via @ZetaVector: ${link}`;
};

export const getBlueskySharePaperLink = ({
  authors,
  id,
  shareUri,
  title,
}: SharePaperArgs): string => {
  const link = shareUri ?? createDocsShareURI([id]);
  const docTitle = title.length >= 120 ? `${title.slice(0, 120)} ...` : title;
  const authorsText = authors.length > 1 ? `${authors[0]} et al` : authors[0];
  return `https://bsky.app/intent/compose?text="${docTitle}" by ${authorsText}. via @ZetaVector: ${link}`;
};

export const getSharePaperLink = {
  [ShareEnum.Email]: getEmailSharePaperLink,
  [ShareEnum.Twitter]: getTwitterSharePaperLink,
  [ShareEnum.Bluesky]: getBlueskySharePaperLink,
  [ShareEnum.Link]: ({ id, shareUri }: SharePaperArgs) =>
    shareUri ?? createDocsShareURI([id]),
};
