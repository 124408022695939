import React, { useState } from 'react';

import { Tab, Tabs, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import TabContent from 'common/components/Tabs/TabContent';
import { useInputCaretPosition } from 'common/hooks/useInputCaretPosition';

import { TextField } from '../../TextField';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    minHeight: 60,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 2),
  },
}));

enum NoteFormTab {
  Preview = 'preview',
  Write = 'write',
}

export type NoteMarkdownFieldProps = {
  helperText?: string;
  label?: string;
  name: string;
  placeholder?: string;
};

// TODO: test
const NoteMarkdownField = React.memo(
  ({ helperText, name, ...textFieldProps }: NoteMarkdownFieldProps) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [field, meta] = useField({ name });
    const hasError = !!(meta.touched && meta.error);
    const helperMessage = hasError ? meta.error : helperText;
    const [activeTab, setActiveTab] = useState<NoteFormTab>(NoteFormTab.Write);
    const { caretPosition, ...restCaretPosEvents } = useInputCaretPosition({
      initPosition: field.value.length,
    });

    const handleTabChange = (
      event: React.ChangeEvent<{}>,
      newValue: NoteFormTab
    ) => {
      setActiveTab(newValue);
    };

    const handleFocus = (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      e.currentTarget.selectionStart = caretPosition;
    };

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab
            label={t('notes.form.tabs.write.text')}
            value={NoteFormTab.Write}
          />
          <Tab
            label={t('notes.form.tabs.preview.text')}
            value={NoteFormTab.Preview}
          />
        </Tabs>

        <TabContent activeTab={activeTab} p={2} tabId={NoteFormTab.Write}>
          <TextField
            {...field}
            {...textFieldProps}
            error={hasError}
            helperText={helperMessage}
            InputProps={{ classes: { input: classes.input } }}
            inputProps={{ ...restCaretPosEvents, onFocus: handleFocus }}
            autoFocus
            fullWidth
            multiline
          />
        </TabContent>

        <TabContent activeTab={activeTab} p={2} tabId={NoteFormTab.Preview}>
          <MarkdownText content={field.value} />
        </TabContent>
      </div>
    );
  }
);

export default NoteMarkdownField;
