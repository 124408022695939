import React, { FC, useMemo } from 'react';

import { ChatMessageElement, Evidence } from 'api/chatApi/chatApi.types';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Optional } from 'common/utils/assert';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { EvidenceButtonWithDialog } from '../Evidence/EvidenceButtonWithDialog';
import { EvidenceList } from '../Evidence/EvidenceList';
import { Parser } from '../hooks/useConversation/parsers/Parser';

import { ConversationMessage } from './ConversationMessage';

interface Props {
  chatNoteId?: number;
  hits?: RetrievalUnitData[];
  initials?: string;
  isEvidenceDialog?: boolean;
  message: ChatMessageElement;
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: Optional<string>
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
}

export const ConversationMessageItem: FC<Props> = ({
  chatNoteId,
  hits,
  initials,
  isEvidenceDialog,
  message,
  onEvidenceChunkClick,
  onEvidenceItemClick,
}) => {
  const content = useMemo(() => {
    const preparedContent = Parser.prepareMessage(message);
    if (!message.content && !message.context && !message.functionCallRequest) {
      return 'No content available';
    }
    return preparedContent;
  }, [message]);

  return (
    <ConversationMessage
      {...message}
      chatNoteId={chatNoteId}
      content={content}
      docsIds={message?.context?.docsIds}
      extra={
        message.evidences ? (
          isEvidenceDialog ? (
            <EvidenceButtonWithDialog
              answerContent={
                <>
                  <MarkdownText content={content} />
                  {message.image && (
                    <NoteAnnotationHighlightItem
                      contentBody={message.image}
                      contentType={'image'}
                    />
                  )}
                </>
              }
              evidences={message.evidences}
              hits={hits}
            />
          ) : (
            <EvidenceList
              isEvidenceDialog={isEvidenceDialog}
              items={message.evidences}
              onEvidenceChunkClick={onEvidenceChunkClick}
              onEvidenceItemClick={onEvidenceItemClick}
            />
          )
        ) : null
      }
      initials={initials}
    />
  );
};
