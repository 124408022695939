import { useCallback } from 'react';

import posthog from 'posthog-js';

import { clearAllCookies } from 'common/utils/cookies';
import {
  getAccessTokenFromStore,
  getRefreshTokenFromStore,
} from 'common/utils/store';
import { removeTokensFromStore } from 'containers/Auth/Auth.utils';

export const useLogout = () => {
  const logout = useCallback(() => {
    try {
      if (!getRefreshTokenFromStore() && !getAccessTokenFromStore()) {
        return;
      }
      removeTokensFromStore();
      clearAllCookies();
      posthog.reset(true);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return { logout };
};
