import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { Nullable } from 'common/utils/assert';
import { Conversation } from 'containers/Chat/Chat.types';
import { ConversationList } from 'containers/Chat/Conversation/ConversationList';
import { isPayloadWithHits } from 'containers/Chat/hooks/helpers';

import { getShortVersionOfNoteContent } from '../getShortVersionOfNoteContent';

import { NoteItemMarkdown } from './NoteItemMarkdown/NoteItemMarkdown';
import { ShortConversationList } from './ShortConversationList/ShortConversationList';

export interface NoteItemContentProps extends BoxProps {
  content: string;
  conversation: Nullable<Conversation>;
  editable?: boolean;
  highlight?: string;
  initials: string | undefined;
  isChatConversation: Nullable<boolean>;
  isShortNote?: boolean;
  noteId: number;
  readMode?: boolean;
}

export const NoteItemContent = ({
  content,
  conversation,
  editable,
  highlight,
  initials,
  isChatConversation,
  isShortNote,
  noteId,
  readMode,
  ...props
}: NoteItemContentProps) => {
  const contentComponent = useMemo(() => {
    if (!conversation || !conversation.messages.length) {
      const text = isShortNote
        ? getShortVersionOfNoteContent(highlight || content)
        : highlight || content;
      return readMode ? (
        <MarkdownText content={text} />
      ) : (
        <NoteItemMarkdown content={text} editable={editable} />
      );
    }

    if (isShortNote) {
      return (
        <ShortConversationList
          content={getShortVersionOfNoteContent(
            conversation.messages[0].content
          )}
          image={conversation.messages[0].image}
        />
      );
    }

    return (
      <ConversationList
        chatNoteId={noteId}
        hits={
          conversation.payload && isPayloadWithHits(conversation.payload)
            ? conversation.payload.hits
            : undefined
        }
        initials={initials}
        messages={conversation.messages}
      />
    );
  }, [
    content,
    conversation,
    editable,
    highlight,
    initials,
    isShortNote,
    noteId,
    readMode,
  ]);

  const maxHeight =
    readMode && !isShortNote
      ? 'none'
      : isShortNote
      ? isChatConversation
        ? 40
        : 100
      : 500;

  const overflowY =
    readMode && !isShortNote
      ? 'visible'
      : isShortNote
      ? isChatConversation
        ? 'initial'
        : 'hidden'
      : 'auto';

  return (
    <Box
      data-testid="NoteItemContent"
      sx={{
        maxHeight,
        overflowY,
      }}
    >
      <Box {...props}>{contentComponent}</Box>
    </Box>
  );
};
