import React, { FC } from 'react';

import { windowReload } from 'common/utils/dom.utils';

import { useLogout } from '../../Auth/hooks/useLogout';

import { NavbarProfileLogoutButtonsLayout } from './NavbarProfileLogoutButtonsLayout';

export const NavbarProfileLogoutCustomButtons: FC = () => {
  const { logout } = useLogout();

  const handleLogout = async () => {
    await logout();
    windowReload();
  };

  return <NavbarProfileLogoutButtonsLayout onLogout={handleLogout} />;
};
