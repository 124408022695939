import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import NotesIcon from '@mui/icons-material/Notes';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  HighlightRect,
  useFullscreen,
  usePages,
  useZoomLevel,
} from '@zarn/pdf-viewer';

import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { VerticalDrawer } from 'common/components/VerticalDrawer/VerticalDrawer';
import { SearchEngineEnum } from 'common/enums';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { useChatAvailability } from 'common/hooks/useChatAvailability';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { useScrollTo } from 'common/hooks/useScrollTo';
import { useSearchTabAvailability } from 'common/hooks/useSearchTabAvailability';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { tryToToggleFullscreen } from 'common/utils/fullscreen';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useChunkDetails } from 'containers/Chat/DocsContext/hooks/useChunkDetails';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import SearchWithNoteOrHighlightDialog from 'containers/SearchWithNoteOrHighlight/SearchWithNoteOrHighlightDialog';
import { SingleEvidenceDialog } from 'containers/TagContent/TagSideDrawer/TagsChat/SingleEvidenceDialog';

import { useSearchPopper } from '../SearchResults/SearchPopper/hooks/useSearchPopper';

import { ChatOfPdfViewer } from './ChatOfPDFViewer/ChatOfPDFViewer';
import { DocTabs } from './DocPDFViewer.interface';
import { getPageDimensions, getTopCoordinate, mapChunk } from './helpers';
import { useDocPDFNotesForm } from './hooks/useDocPDFNotesForm';
import { useDocPDFViewerHighlights } from './hooks/useDocPDFViewerHighlights';
import { useHighlightAction } from './hooks/useHighlightAction';
import { useSingleEvidenceDialog } from './hooks/useSingleEvidenceDialog';
import { useVirtualHighlights } from './hooks/useVirtualHighlights';
import { InfoNotes } from './InfoNotes/InfoNotes';
import { LoadingErrorData } from './LoadingErrorData/LoadingErrorData';
import { useStyles } from './style';
import { withPDFViewer } from './withPDFViewer';

export interface DocPDFViewerDialogProps extends Pick<DialogProps, 'open'> {
  docData?: RetrievalUnitData | null;
  docId: string;
  initialChunkId?: string | null;
  onClose: () => void;
  searchEngine?: SearchEngineEnum;
}

const DocPDFViewerDialogComponent: FC<DocPDFViewerDialogProps> = ({
  docData,
  docId,
  initialChunkId,
  onClose,
  open,
  searchEngine,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'chat']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const documentRef = useRef<HTMLDivElement | null>(null);
  const { fullscreen } = useFullscreen();
  const { zoomLevel } = useZoomLevel();
  const { initialValues, notesPayload, onSubmit } = useDocPDFNotesForm();
  const { tenant } = useParsedHostname();

  const { activeHighlight, highlights, setActiveHighlight } =
    useDocPDFViewerHighlights({ docId, notesPayload });

  const { addVirtualHighlight, virtualHighlights } =
    useVirtualHighlights(highlights);

  const {
    dialogOpen: searchPopperOpen,
    onClose: onSearchPopperClose,
    onOpen: onSearchPopperOpen,
    searchQuery,
  } = useSearchPopper();
  const { tab } = useLocationParams('tab');
  const activeTabFromUrl = tab === 'notes' ? DocTabs.InfoNotes : DocTabs.Chat;
  const [activeTab, setActiveTab] = useState<DocTabs>(activeTabFromUrl);
  const { scrollTo } = useScrollTo(documentRef);

  useEffect(() => {
    setActiveTab(activeTabFromUrl);
  }, [activeTabFromUrl]);

  const { isChatAvailable } = useChatAvailability();
  const { supportedBotTypes, tenantSettings } = useAssertTenantSettings();
  const { isGoogleScholarTab } = useSearchTabAvailability();
  const { setParams } = useQuerySearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const getTopForScrolling = useCallback(
    (
      { pageNumber, y1 }: Pick<HighlightRect, 'pageNumber' | 'y1'>,
      pageHeight: number
    ) => Math.floor(pageHeight * (pageNumber - 1) + y1 * zoomLevel - 100),
    [zoomLevel]
  );

  const { numberOfPages } = usePages();

  const getPageHeight = useCallback(
    () =>
      documentRef.current
        ? documentRef.current.scrollHeight / numberOfPages
        : null,
    [documentRef, numberOfPages]
  );

  const handleHighlightClick = useCallback(
    ({ rects }: AnnotationHighlight) => {
      const rect = rects[0];

      const pageHeight = getPageHeight();

      if (!pageHeight || !documentRef) {
        return;
      }

      scrollTo(getTopForScrolling(rect, pageHeight));
    },
    [getPageHeight, getTopForScrolling, scrollTo]
  );

  const changeTab = useCallback(
    (newTab: DocTabs) => {
      setParams({
        tab: newTab,
      });
      setActiveTab(newTab);
    },
    [setParams]
  );

  const { onHighlight } = useHighlightAction({
    docId,
    onAddNote: (highlight) => {
      changeTab(DocTabs.InfoNotes);
      setActiveHighlight(highlight.id);
    },
    onClick: () => {},
    onCreate: (_note) => {
      setActiveHighlight(_note.id);

      enqueueSnackbar(t('notes.highlights.created'), {
        SnackbarProps: {
          onClick: () => {
            changeTab(DocTabs.InfoNotes);
          },
        },
        style: { cursor: 'pointer' },
      });
    },
    onExplain: (highlight) => {
      posthogPDFReader.chat(
        tenant,
        highlight.content.body.includes('data:image/png;base64')
      );
      setParams({
        explainText: highlight.content.body,
        tab: DocTabs.Chat,
      });
    },
    onSearch: onSearchPopperOpen,
  });

  useEffect(() => {
    tryToToggleFullscreen(fullscreen);
  }, [fullscreen]);

  const { chunkId, closeEvidenceDialog, evidenceDialogOpen, setChunkId } =
    useSingleEvidenceDialog();

  const chatTab =
    activeTab === DocTabs.Chat && isChatAvailable && !isGoogleScholarTab;

  const handleEvidenceChunkClick = useCallback(
    (chunks: ChunkHighlight[]) => {
      const { height: pageHeight, width: pageWidth } = getPageDimensions();
      const temporaryHighlights = chunks.map(
        mapChunk(pageWidth, pageHeight, zoomLevel)
      );
      addVirtualHighlight(temporaryHighlights);

      const rect = chunks[0];
      if (!rect) {
        return;
      }
      scrollTo(
        getTopCoordinate({ ...rect, y1: rect.y1 }, getPageDimensions().height)
      );
    },
    [addVirtualHighlight, scrollTo, zoomLevel]
  );

  const hasScrolledRef = useRef(false);

  const { data: chunkData } = useChunkDetails({
    chunkId: initialChunkId ?? null,
  });
  const chunkHighlights =
    chunkData?.[0]?.representations.chunkHighlights ?? null;

  useEffect(() => {
    if (chunkHighlights && !hasScrolledRef.current) {
      const scrollWhenPdfViewerIsLoaded = () => {
        const isPdfViewerLoaded =
          document.querySelector('.react-pdf__Page') !== null;
        if (isPdfViewerLoaded) {
          handleEvidenceChunkClick(chunkHighlights);
          hasScrolledRef.current = true;
        }
      };

      const intervalId = setInterval(() => {
        scrollWhenPdfViewerIsLoaded();
        if (hasScrolledRef.current) {
          clearInterval(intervalId);
        }
      }, 100);
      const timeoutId = setTimeout(() => clearInterval(intervalId), 20000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [chunkHighlights, handleEvidenceChunkClick]);

  useEffect(() => {
    hasScrolledRef.current = false;
  }, [chunkHighlights]);
  return (
    <Dialog
      aria-label={t('pdfViewer.dialog.ariaLabel')}
      data-testid="DocPDFViewerDialogComponent"
      fullScreen={isMobile ? isMobile : fullscreen}
      maxWidth="xl"
      open={open}
      scroll="paper"
      sx={{ '& .MuiDialog-paper': { height: '100%' } }}
      disableEscapeKeyDown
      fullWidth
      onClose={onClose}
    >
      <TrackedActionContext.Provider
        value={{ actionContext: 'PDF viewer annotations', docId }}
      >
        <div className={classes.content}>
          <div className={classes.viewer}>
            <LoadingErrorData
              docData={docData}
              docId={docId}
              documentRef={documentRef}
              highlights={virtualHighlights}
              // @ts-ignore
              onHighlight={onHighlight}
            />
          </div>
          <VerticalDrawer
            actions={
              isChatAvailable ? (
                <>
                  <Tooltip
                    placement="left"
                    title={t('pdfViewer.drawerActions.chat')}
                  >
                    <span>
                      <TrackedIconButton
                        aria-label={t('pdfViewer.drawerActions.chat')}
                        className={clsx(
                          activeTab === DocTabs.Chat && classes.activeTab
                        )}
                        data-testid="chat-tab"
                        disabled={activeTab === DocTabs.Chat}
                        eventName={TrackEventName.ClickOnChatInPDF}
                        size="large"
                        sx={{ mb: 1 }}
                        onClick={() => changeTab(DocTabs.Chat)}
                      >
                        <OrangeGradientOpenAiIcon />
                      </TrackedIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title={t('pdfViewer.drawerActions.notes')}
                  >
                    <span>
                      <TrackedIconButton
                        aria-label={t('pdfViewer.drawerActions.notes')}
                        className={clsx(
                          activeTab === DocTabs.InfoNotes && classes.activeTab
                        )}
                        data-testid="notes-tab"
                        disabled={activeTab === DocTabs.InfoNotes}
                        eventName={TrackEventName.ClickOnInfoInPDF}
                        size="large"
                        onClick={() => changeTab(DocTabs.InfoNotes)}
                      >
                        <NotesIcon />
                      </TrackedIconButton>
                    </span>
                  </Tooltip>
                </>
              ) : null
            }
            onClose={onClose}
          >
            {chatTab && tenantSettings.chat.defaultBot ? (
              <>
                {docData ? (
                  <ChatOfPdfViewer
                    defaultBotType={tenantSettings.chat.defaultBot}
                    docData={docData}
                    docId={docId}
                    otherDocumentEvidenceChunkClick={setChunkId}
                    scrollTo={scrollTo}
                    searchEngine={searchEngine}
                    supportedBotTypes={supportedBotTypes}
                    onAddHighlights={addVirtualHighlight}
                  />
                ) : (
                  <Box display="flex" justifyContent="center" m="auto">
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </>
            ) : (
              <InfoNotes
                activeHighlight={activeHighlight}
                docId={docId}
                handleHighlightClick={handleHighlightClick}
                initialValues={initialValues}
                notesPayload={notesPayload}
                searchEngine={searchEngine}
                onSubmit={onSubmit}
              />
            )}
          </VerticalDrawer>
          <SearchWithNoteOrHighlightDialog
            docId={docId}
            searchPopperOpen={searchPopperOpen}
            searchQuery={searchQuery}
            onSearchPopperClose={onSearchPopperClose}
          />
        </div>
        <SingleEvidenceDialog
          chunkId={chunkId}
          closeDialog={closeEvidenceDialog}
          dialogOpen={evidenceDialogOpen}
        />
      </TrackedActionContext.Provider>
    </Dialog>
  );
};

export const DocPDFViewerDialog = withPDFViewer(DocPDFViewerDialogComponent);
