import { ShareEnum } from 'common/enums';
import { getId } from 'common/utils/docIdHelpers';
import {
  D_NEW_LINE,
  EMAIL_BODY,
  EMAIL_SUBJECT,
  getAuthorsText,
  getNotesText,
  NEW_LINE,
} from 'common/utils/share';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import {
  SavedNote,
  SavedNotes,
} from 'containers/SavedNotes/SavedNotes.interface';

import { getAuthorsFullNames } from '../RetrievalUnit/retrievalUnit.utils';

import { ShareTagArgs } from './ShareTag.interface';

export const getTaggedDocsEmailContent = ({
  documentsNotes,
  taggedDocs,
}: Omit<ShareTagArgs, 'tagName' | 'tagId'>): string => {
  const docTexts = taggedDocs.reduce((acc, retrievalUnitData) => {
    const authors = getAuthorsFullNames(retrievalUnitData);
    const authorsText = getAuthorsText(authors);
    const notes = documentsNotes.find(
      ({ id }) => id === retrievalUnitData.document.id
    );
    const notesText = (notes && getNotesText(notes.notesData)) || '';
    acc.push(
      `"${retrievalUnitData.title}" ${NEW_LINE}by ${authorsText}${notesText}`
    );
    return acc;
  }, [] as string[]);

  return docTexts.join(D_NEW_LINE);
};

export const getSharedTagLink = (tagId: number): string =>
  `${window.env.ORIGIN_URL}/tags/${tagId}`;

/**
 * @deprecated
 */
export const getEmailShareTagLink_depr = (args: ShareTagArgs): string => {
  const emailContent = getTaggedDocsEmailContent(args);
  const link = getSharedTagLink(args.tagId);
  const tagsNotes = getNotesText(args.tagsNotes);
  return `mailto:?subject=${EMAIL_SUBJECT.MULTI_PAPERS}&body=${EMAIL_BODY.MULTI_PAPERS} "${args.tagName}" via Zeta Alpha: ${tagsNotes}${D_NEW_LINE}Documents:${D_NEW_LINE}${emailContent} ${D_NEW_LINE}${link}`;
};

export const getTwitterShareTagLink = (
  tagId: number,
  tagName: string
): string => {
  const link = getSharedTagLink(tagId);
  return `https://x.com/intent/tweet?text=A collection of papers about "${tagName}" via @ZetaVector: ${link}`;
};

export const getBlueskyShareTagLink = (
  tagId: number,
  tagName: string
): string => {
  const link = getSharedTagLink(tagId);
  return `https://bsky.app/intent/compose?text=A collection of papers about "${tagName}" via @ZetaVector: ${link}`;
};

export const getShareTagUri = {
  [ShareEnum.Email]: getEmailShareTagLink_depr,
};

export const getTaggedDocsNotes = (
  savedDocNotes: SavedNotes,
  taggedDocs: RetrievalUnitData[]
): SavedNote[] => {
  return Object.values(savedDocNotes).filter(({ id }) =>
    taggedDocs.some((taggedDoc) => taggedDoc.document.id === id)
  );
};

export const getDocsNotesIdsWithoutData = (
  taggedDocsNotes: SavedNote[]
): string[] =>
  taggedDocsNotes
    .filter(
      ({ notesData, numberOfNotes }) => numberOfNotes !== notesData.length
    )
    .map(getId);
