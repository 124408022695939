import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Button, Link } from '@mui/material';
import { useSnackbar } from 'notistack';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useNotesList } from 'containers/SavedNotes/hooks/useNotesList';

import { getReportLink } from './reports.utils';

const LOCAL_STORAGE_KEY = 'tasks';

type NoteStatus = 'success' | 'error' | 'read';

interface INoteReportTask {
  chatNoteId: number;
  note?: NoteDetails;
  reportNoteId: number;
  status?: NoteStatus;
}

interface IState {
  tasks: INoteReportTask[];
}

const loadingTasks = () => {
  try {
    const tasks = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!tasks) {
      return [];
    }
    return JSON.parse(tasks);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const DEFAULT_STATE: IState = {
  tasks: loadingTasks(),
};

interface IStore {
  readReport: (chatNoteId: number, reportNoteId: number) => void;
  state: IState;
  trackNote: (chatNoteId: number, reportNoteId: number) => void;
}

const NotesReportsLoadingContext = createContext<IStore | undefined>(undefined);

const isNeedToLoadNote = (task: INoteReportTask) =>
  !task.status ||
  (task.status && ['success', 'read'].includes(task.status) && !task.note);

export const NotesReportsLoadingProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { tenant } = useParsedHostname();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useAuth();

  const [state, setState] = useState<IState>(DEFAULT_STATE);

  const reportsNotesIds = useMemo(
    () =>
      state.tasks.filter(isNeedToLoadNote).map((t) => Number(t.reportNoteId)),
    [state.tasks]
  );

  const saveTasks = useCallback((tasks: INoteReportTask[]) => {
    const tasksForSave = tasks.map((task) => {
      const taskForSave = { ...task };
      if ('note' in taskForSave) {
        delete taskForSave.note;
      }
      return taskForSave;
    });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(tasksForSave));
  }, []);

  const processNotes = useCallback(
    (notes: NoteDetails[]) => {
      setState((prevState) => {
        const newNotes = notes.filter(
          (note) =>
            prevState.tasks
              .filter(isNeedToLoadNote)
              .filter((t) => +t.reportNoteId === +note.id).length > 0
        );
        if (!newNotes.length) {
          return prevState;
        }

        const tasks = prevState.tasks.map((task) => {
          const taskNode = newNotes.find((n) => +n.id === +task.reportNoteId);
          if (taskNode) {
            task.note = taskNode;
            if (!task.status) {
              if (task.note.content.startsWith('Report generation failed')) {
                task.status = 'error';
                enqueueSnackbar('Report generation failed', {
                  variant: 'error',
                });
              } else if (
                !task.note.content.startsWith('Report generation in progress')
              ) {
                task.status = 'success';
                enqueueSnackbar('The research report is ready', {
                  action: (
                    <Button
                      color="secondary"
                      component={Link}
                      href={getReportLink(task.chatNoteId)}
                    >
                      Open Report
                    </Button>
                  ),
                  autoHideDuration: 3000,
                });
              }
            }
          }
          return task;
        });

        saveTasks(tasks);

        return {
          ...prevState,
          tasks,
        };
      });
    },
    [enqueueSnackbar, saveTasks]
  );

  const request = useNotesList({
    noteIds: reportsNotesIds,
    options: {
      enabled: isAuthenticated && !!reportsNotesIds.length,
    },
    tenant,
  });

  useEffect(() => {
    if (request.query.data?.items) {
      processNotes(request.query.data.items);
    }
  }, [processNotes, request.query.data?.items]);

  const trackNote = useCallback(
    (chatNoteId: number, reportNoteId: number) => {
      if (!isAuthenticated) {
        return;
      }
      setState((prevState) => {
        const taskIndex = prevState.tasks.findIndex(
          (t) => t.reportNoteId === reportNoteId
        );

        if (taskIndex !== -1) {
          return prevState;
        }

        const newState = {
          ...prevState,
          tasks: [...prevState.tasks, { chatNoteId, reportNoteId }],
        };

        saveTasks(newState.tasks);

        return newState;
      });
    },
    [isAuthenticated, saveTasks]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAuthenticated) {
        clearInterval(interval);
        return;
      }
      if (!reportsNotesIds.length) {
        return;
      }
      setState((prevState) => {
        void request.query.refetch().then((resp) => {
          if (resp.data?.items) {
            processNotes(resp.data.items);
          }
        });

        return prevState;
      });
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [processNotes, request.query, reportsNotesIds, isAuthenticated]);

  const readReport = useCallback(
    (chatNoteId: number, reportNoteId: number) => {
      const note = state.tasks.find(
        (t) => t.chatNoteId === chatNoteId && t.reportNoteId === reportNoteId
      );
      if (!note) {
        return;
      }
      note.status = 'read';
      saveTasks([...state.tasks]);
      setState((prevState) => ({ ...prevState, tasks: [...state.tasks] }));
    },
    [saveTasks, state.tasks]
  );

  return (
    <NotesReportsLoadingContext.Provider
      value={{ readReport, state, trackNote }}
    >
      {children}
    </NotesReportsLoadingContext.Provider>
  );
};

export const useAsyncLoading = () => {
  const context = useContext(NotesReportsLoadingContext);
  if (!context) {
    throw new Error(
      'useAsyncLoading must be used within a NotesReportsLoadingProvider'
    );
  }

  return context;
};

export const useNoteReportLoading = (
  chatNoteId: number,
  reportNoteId: number
): Omit<INoteReportTask, 'chatNoteId' | 'reportNoteId'> => {
  const { state, trackNote } = useAsyncLoading();
  const task = state.tasks.find((t) => t.reportNoteId === reportNoteId);

  useEffect(() => {
    trackNote(chatNoteId, reportNoteId);
  }, [chatNoteId, reportNoteId, trackNote]);

  return {
    note: task?.note ?? undefined,
    status: task?.status ?? undefined,
  };
};
