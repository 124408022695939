export enum ShareEnum {
  Bluesky = 'bluesky',
  Email = 'email',
  Link = 'link',
  Twitter = 'twitter',
}

export type ShareType =
  | ShareEnum.Email
  | ShareEnum.Twitter
  | ShareEnum.Link
  | ShareEnum.Bluesky;
