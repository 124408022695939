import { useMemo, useState } from 'react';

import { captureException } from '@sentry/react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { PASSWORD_REGEX } from 'common/constants';
import { windowReload } from 'common/utils/dom.utils';
import { useLogin } from 'containers/Auth/hooks/useLogin';
import { useLogout } from 'containers/Auth/hooks/useLogout';

import { LoginFormValues } from '../LoginForm.interface';

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

export type UseLoginFormProps = {
  onReset?: () => void;
  onSubmit?: () => void;
};

export const useLoginForm = ({ onReset, onSubmit }: UseLoginFormProps) => {
  const { t } = useTranslation('common');
  const logIn = useLogin();
  const { logout } = useLogout();
  const [error, setError] = useState<string | null>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string()
          .matches(PASSWORD_REGEX, t('user.login.password.error'))
          .required('Required'),
      }),
    [t]
  );

  const handleReset = () => {
    logout();
    onReset?.();
    windowReload();
  };

  const handleSubmit = async (
    values: LoginFormValues,
    {
      setFieldError,
      setSubmitting,
      validateForm,
    }: FormikHelpers<LoginFormValues>
  ): Promise<void> => {
    await validateForm(values);

    try {
      setSubmitting(true);
      await logIn(values);
      onSubmit?.();
    } catch (err: any) {
      captureException(err);
      const passwordError = err?.response?.data?.detail;
      const errorMessage = err?.response?.data?.message;
      if (passwordError) setFieldError('password', passwordError);
      if (errorMessage) setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    error,
    initialValues,
    onReset: handleReset,
    onSubmit: handleSubmit,
    validationSchema,
  };
};
