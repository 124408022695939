import {
  DocumentsApiDocumentSearchPostRequest,
  DocumentsApiDocumentSearchRequest,
  SearchPostRequest,
  SortSchema,
} from '@zarn/vendor/dist/search';

import {
  SearchSortField,
  SearchTermEnum,
  SearchTermEnumType,
} from 'common/enums';
import { Optional } from 'common/utils/assert';
import {
  SearchApiDocumentSearchRequestRange,
  SearchPayload,
  SearchPayloadDate,
  SearchPayloadSort,
  SearchPayloadTerm,
  SearchPayloadYear,
} from 'containers/Search/SearchPayload.interface';

import { DEFAULT_DOC_TYPES } from './constants/defaultSearchValues';
import { serializeFilters } from './dynamicSearch.utils';

export const serializeTimeRange = (
  range: SearchPayloadYear | SearchPayloadDate | undefined
): SearchApiDocumentSearchRequestRange | undefined => {
  return range
    ? {
        lower_bound: String(range[0]),
        upper_bound: String(range[1]),
      }
    : undefined;
};

const serializeSort = (
  sort: SearchPayloadSort | undefined
): SortSchema | undefined => {
  return sort && { [sort[0]]: sort[1] };
};

const serializeSortOrder = (
  sort: SearchPayloadSort | undefined
): SearchSortField[] | undefined => {
  return sort && [sort[0]];
};

export const serializeEntity = (
  term: SearchPayloadTerm | undefined,
  termType: SearchTermEnumType
): string | undefined => {
  return term && term[0] === termType ? term[1] : undefined;
};

const serializeArray = <T>(array: Optional<Array<T>>): T[] | undefined =>
  array && array.length > 0 ? array : undefined;

export const serializeSearchPayload = (
  payload: SearchPayload
): DocumentsApiDocumentSearchRequest => {
  const { date, sort, tenant, term, year } = payload;

  return {
    authoredBy: serializeEntity(term, SearchTermEnum.AuthoredBy),
    citedBy: serializeEntity(term, SearchTermEnum.CitedBy),
    cites: serializeEntity(term, SearchTermEnum.Cites),
    date: serializeTimeRange(date),
    docIds: payload.docIds,
    documentTypes: payload.docTypes ?? DEFAULT_DOC_TYPES,
    hasConcept: serializeEntity(term, SearchTermEnum.HasConcept),
    indexCluster:
      payload.indexCluster === null ? undefined : payload.indexCluster,
    locationCountries: payload.locationCountries,
    organizations: payload.organizations,
    page: payload.page,
    queryEncoderService: payload.queryEncoderService,
    queryString: payload.queryString,
    rerank: payload.rerank,
    // reranker: payload.reranker,
    rerankTopN: payload.rerankTopN,

    rerankerService: payload.rerankerService,

    retrievalMethod: payload.retrievalMethod,

    retrievalUnit: payload.retrievalUnit,

    searchEngine: payload.searchEngine,

    similarTo: payload.similarTo,

    sort: serializeSort(sort),

    sortOrder: serializeSortOrder(sort),

    sources: payload.docSources,

    tagIds: serializeArray(payload.tagIds),
    tenant: tenant,
    visibility: payload.visibility,
    withCode: payload.withCode,
    year: serializeTimeRange(year),
  };
};

export const serializeSearchPostPayload = (
  payload: SearchPayload
): DocumentsApiDocumentSearchPostRequest => {
  const { date, sort, tenant, term, year } = payload;

  const searchPostRequest: SearchPostRequest = {
    authored_by: serializeEntity(term, SearchTermEnum.AuthoredBy),
    cited_by: serializeEntity(term, SearchTermEnum.CitedBy),
    cites: serializeEntity(term, SearchTermEnum.Cites),
    date: serializeTimeRange(date),
    doc_ids: serializeArray(payload.docIds),
    document_types: payload.docTypes ?? DEFAULT_DOC_TYPES,
    filters: serializeFilters(payload.dynamicFilters),
    has_concept: serializeEntity(term, SearchTermEnum.HasConcept),
    index_cluster:
      payload.indexCluster === null ? undefined : payload.indexCluster,

    location_countries: serializeArray(payload.locationCountries),

    organizations: serializeArray(payload.organizations),

    page: payload.page,
    page_size: payload.pageSize,

    query_encoder_service: payload.queryEncoderService,

    query_string: payload.queryString,

    rerank: payload.rerank,

    rerank_top_n: payload.rerankTopN,

    reranker_service: payload.reranker,

    retrieval_method: payload.retrievalMethod,

    retrieval_unit: payload.retrievalUnit,

    search_engine: payload.searchEngine,

    similar_to: payload.similarTo,

    sort: serializeSort(sort),

    sort_order: serializeSortOrder(sort),

    sources: serializeArray(payload.docSources),

    tag_ids: serializeArray(payload.tagIds),

    tenant: tenant,

    visibility: serializeArray(payload.visibility),

    with_code: payload.withCode,
    // Custom fields:
    year: serializeTimeRange(year),
  };

  return {
    searchPostRequest,
  };
};
