import React, { FC, useEffect, useMemo, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import BetaButton from 'common/components/Buttons/BetaButton';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { useSearchResultsLogging } from 'common/hooks/useSearchResultsLogging';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { useSearchResults } from 'containers/SearchResults/hooks/useSearchResults';
import { selectIndexCluster } from 'containers/User/user.slice';

import ExplainHighlightInput from './ExplainHighlightInput';
import { ExplainHighlightResult } from './ExplainHighlightResult';
import { ExplainHighlightResultSkeleton } from './ExplainHighlightResultSkeleton';
import { useExplanation } from './hooks/useExplanation';

export interface ExplainHighlightCardProps {
  docId?: string;
  searchQuery: string;
}

const ExplainHighlightCard: FC<ExplainHighlightCardProps> = ({
  docId,
  searchQuery,
}) => {
  const { t } = useTranslation('search');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [docsIds, setDocsIds] = useState<string[]>([]);
  const [docIdsHits, setDocIdsHits] = useState<{
    [key: string]: RetrievalUnitData;
  }>({});
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);
  const { searchDynamicsFilters } = useAssertTenantSettings();

  const searchPayload = useMemo(
    () =>
      new SearchPayloadFromURL(
        searchQuery,
        parsedHostname,
        undefined,
        indexCluster,
        searchDynamicsFilters
      ).getSearchPayload(),
    [indexCluster, parsedHostname, searchDynamicsFilters, searchQuery]
  );
  const { data, isLoading } = useSearchResults({
    ...searchPayload,
    options: {
      retry: 1,
    },
  });
  const [updatedQuestion, setUpdatedQuestion] = useState<string>(
    searchPayload.queryString
  );

  const { data: explanation, isLoading: explanationIsLoading } = useExplanation(
    {
      docIdHitsMap: docIdsHits,
      docsIds,
      passage: updatedQuestion,
      retrievalUnit: searchPayload.retrievalUnit,
    }
  );

  const { updateExplanationSearchLog } = useSearchResultsLogging();

  const editModeToggle = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (data) {
      const hits = data.data.items.reduce(
        (acc: { [key: string]: RetrievalUnitData }, hit) => ({
          ...acc,
          [hit.document.id]: hit,
        }),
        {} as { [key: string]: RetrievalUnitData }
      );
      setDocIdsHits(hits);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const dataDocsIds = (data?.data.items ?? []).map(
      ({ document }) => document.id
    );

    const filteredIds = [docId, ...dataDocsIds].filter(
      (el): el is string => el !== null
    );

    setDocsIds(filteredIds);
  }, [data?.data.items, docId, isLoading]);

  useEffect(() => {
    setEditMode(false);
  }, [updatedQuestion]);

  useEffect(() => {
    if (explanation && docsIds.length > 0) {
      void updateExplanationSearchLog({
        docIds: docsIds,
        explanation: explanation.explanation,
        passage: updatedQuestion,
        system: ResultType.Explanation,
      });
    }
  }, [explanation, docsIds, updateExplanationSearchLog, updatedQuestion]);

  const handleChangeInput = (content: string) => {
    setUpdatedQuestion(content);
  };

  useEffect(() => {
    if (searchPayload.queryString) {
      setUpdatedQuestion(searchPayload.queryString);
    }
  }, [searchPayload.queryString]);

  if (explanationIsLoading) {
    return <ExplainHighlightResultSkeleton />;
  }

  if (explanation?.explanation) {
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'left', p: 0 }}>
          {!editMode ? (
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box pr={1}>
                <OrangeGradientOpenAiIcon />
              </Box>

              <Typography
                pr={1}
                sx={{ alignSelf: 'center', cursor: 'pointer' }}
                onClick={editModeToggle}
              >
                {`${updatedQuestion.substring(0, 200)} ${
                  updatedQuestion.length > 200 ? '...' : ''
                }`}
                <EditIcon fontSize="small" sx={{ ml: 2 }} />
              </Typography>
            </Box>
          ) : (
            <ExplainHighlightInput
              editModeToggle={editModeToggle}
              pr={1}
              question={updatedQuestion}
              onAdd={handleChangeInput}
            />
          )}
          <Box p={1}>
            <BetaButton
              bodyText={t('explainHighlight.beta.body')}
              headerText={t('explainHighlight.beta.header')}
              idValue="explainHighlightPopover"
            />
          </Box>
        </Box>
        <ExplainHighlightResult
          evidences={explanation.evidences}
          explanation={explanation.explanation}
        />
      </Box>
    );
  }

  return null;
};

ExplainHighlightCard.displayName = 'ExplainHighlightCard';

export default ExplainHighlightCard;
