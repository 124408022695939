import React, { FC } from 'react';

import { Optional } from 'common/utils/assert';
import { RetrievalUnitCardSimple } from 'containers/RetrievalUnit/RetrievalUnitCard/RetrievalUnitCardSimple';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

interface Props {
  content: Optional<string>;
  href: Optional<string>;
  item: Optional<RetrievalUnitData>;
}

export const SimpleItem: FC<Props> = ({ content, href, item }) => {
  const data: Partial<RetrievalUnitData> = item ? { ...item } : {};
  if (href) {
    let hrefDomain;
    try {
      hrefDomain = new URL(href).hostname;
    } catch (e) {
      hrefDomain = '';
    }
    data.uri = href;
    data.title = item?.title ?? hrefDomain;
  }
  if (content) {
    data.abstractContent = content;
    data.highlight = '';
  }

  return <RetrievalUnitCardSimple data={data} />;
};
