import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  href: string;
}

export const LinkBlank: FC<Props> = ({ children, href = '#' }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    style={{ color: 'blue', textDecoration: 'underline' }}
    target="_blank"
  >
    {children}
  </a>
);
