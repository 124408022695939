import React, { useCallback, useEffect, useState } from 'react';

import { Dialog, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { windowReload } from 'common/utils/dom.utils';
import { LoginDialogState } from 'containers/Auth/Auth.context';
import { useAuth } from 'containers/Auth/hooks/useAuth';

import ForgotPasswordForm from '../ForgotPassword';
import { useLogout } from '../hooks/useLogout';

import { useLoginDialog } from './hooks/useLoginDialog';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

const LoginDialog = (): JSX.Element => {
  const { loginDialog, me, setLoginDialog } = useAuth();
  const history = useHistory();
  const { pathname, push, replace, search, state } = useNavigationWithState();
  const { logout } = useLogout();
  const [open, setOpen] = useState<boolean>(
    loginDialog !== LoginDialogState.Closed
  );
  const isResetPasswordDialog = loginDialog === LoginDialogState.ResetPassword;
  const isSignUpDialog = loginDialog === LoginDialogState.SignUp;
  const { ariaLabel, dialogId, dialogTitle } = useLoginDialog(loginDialog);

  const handleClose = useCallback(() => {
    setLoginDialog(LoginDialogState.Closed);
  }, [setLoginDialog]);

  const handleDialogClose = useCallback(() => {
    logout();
    setOpen(false);
    history.replace(`${pathname}${search}`, {});
  }, [logout, pathname, history, search]);

  const handleSignUp = useCallback(() => {
    replace(`${pathname}${search}`, {
      from: state?.from,
      login: true,
    });
  }, [pathname, replace, search, state?.from]);

  const handleLogin = useCallback(() => {
    push(state?.from ?? '/');
    windowReload();
  }, [push, state?.from]);

  useEffect(() => {
    setOpen(loginDialog !== LoginDialogState.Closed);
  }, [loginDialog]);

  useEffect(() => {
    if (!me && state?.login) {
      setLoginDialog(LoginDialogState.Login);
    }
    if (!me && state?.signUp) {
      setLoginDialog(LoginDialogState.SignUp);
    }
  }, [me, state, setLoginDialog]);

  return (
    <Dialog
      aria-label={ariaLabel}
      data-testid="LoginDialog"
      id={dialogId}
      maxWidth="xs"
      open={open}
      scroll="body"
      TransitionProps={{ onExited: handleClose }}
      disableEscapeKeyDown
      fullWidth
    >
      <DialogActionTitle onClose={handleDialogClose}>
        <Typography variant="h5">{dialogTitle}</Typography>
      </DialogActionTitle>

      {isResetPasswordDialog ? (
        <ForgotPasswordForm
          handleClose={handleClose}
          setOpenState={setLoginDialog}
        />
      ) : isSignUpDialog ? (
        <SignUpForm
          setOpenState={setLoginDialog}
          onClose={handleClose}
          onFormSubmit={handleSignUp}
        />
      ) : (
        <LoginForm
          setOpenState={setLoginDialog}
          onClose={handleClose}
          onLogin={handleLogin}
        />
      )}
    </Dialog>
  );
};

export default LoginDialog;
