import React from 'react';

import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.10613 5.448C9.70413 3.698 12.1221 3.645 12.8311 5.289L12.8911 5.449L13.6981 7.809C13.8831 8.35023 14.1819 8.84551 14.5746 9.26142C14.9672 9.67734 15.4444 10.0042 15.9741 10.22L16.1911 10.301L18.5511 11.107C20.3011 11.705 20.3541 14.123 18.7111 14.832L18.5511 14.892L16.1911 15.699C15.6497 15.8838 15.1542 16.1826 14.7381 16.5753C14.3221 16.9679 13.995 17.4452 13.7791 17.975L13.6981 18.191L12.8921 20.552C12.2941 22.302 9.87613 22.355 9.16813 20.712L9.10613 20.552L8.30013 18.192C8.11531 17.6506 7.8165 17.1551 7.42387 16.739C7.03124 16.3229 6.55392 15.9959 6.02413 15.78L5.80813 15.699L3.44813 14.893C1.69713 14.295 1.64413 11.877 3.28813 11.169L3.44813 11.107L5.80813 10.301C6.34936 10.1161 6.84464 9.81719 7.26055 9.42457C7.67646 9.03195 8.00334 8.55469 8.21913 8.025L8.30013 7.809L9.10613 5.448ZM18.9991 2C19.1862 2 19.3695 2.05248 19.5283 2.15147C19.687 2.25046 19.8148 2.392 19.8971 2.56L19.9451 2.677L20.2951 3.703L21.3221 4.053C21.5096 4.1167 21.674 4.23462 21.7944 4.39182C21.9148 4.54902 21.9858 4.73842 21.9984 4.93602C22.011 5.13362 21.9647 5.33053 21.8653 5.50179C21.766 5.67304 21.618 5.81094 21.4401 5.898L21.3221 5.946L20.2961 6.296L19.9461 7.323C19.8823 7.51043 19.7643 7.6747 19.6071 7.79499C19.4498 7.91529 19.2604 7.98619 19.0628 7.99872C18.8652 8.01125 18.6683 7.96484 18.4971 7.86538C18.3259 7.76591 18.1881 7.61787 18.1011 7.44L18.0531 7.323L17.7031 6.297L16.6761 5.947C16.4886 5.8833 16.3243 5.76538 16.2039 5.60819C16.0835 5.45099 16.0125 5.26158 15.9999 5.06398C15.9872 4.86638 16.0335 4.66947 16.1329 4.49821C16.2323 4.32696 16.3803 4.18906 16.5581 4.102L16.6761 4.054L17.7021 3.704L18.0521 2.677C18.1196 2.47943 18.2471 2.30791 18.417 2.1865C18.5868 2.06509 18.7904 1.99987 18.9991 2Z" />
  </svg>,
  'OpenAiIcon'
);
