import React, { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { PaperProps } from '@mui/material/Paper/Paper';
import { useSelector } from 'react-redux';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import {
  BoxWithoutScroll,
  PaperWithoutScroll,
} from 'common/components/WithoutScroll/WithoutScroll';
import { selectUser } from 'containers/User/user.slice';

import { Avatar } from './Avatar';

interface MessageProps extends PaperProps {
  avatar?: ReactNode;
  button?: ReactNode;
  children: ReactNode;
  extra?: ReactNode;
  initials?: string;
  isContent: boolean;
  sender: ChatMessageSenderEnum | undefined;
  topExtra?: ReactNode;
}

export const Message: FC<MessageProps> = ({
  avatar,
  button,
  children,
  extra,
  initials,
  isContent,
  sender,
  topExtra,
  ...props
}) => {
  const user = useSelector(selectUser);
  const isUser = sender === ChatMessageSenderEnum.User;

  if (!user) return null;

  return (
    <Box
      p={1}
      sx={{
        display: 'flex',
        flexDirection: isUser ? 'row-reverse' : 'row',
      }}
    >
      {(isContent || button || topExtra) && (
        <Avatar
          content={avatar ? avatar : initials}
          isContent={isUser || !!avatar}
        />
      )}
      <BoxWithoutScroll
        sx={{
          alignItems: 'end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          overflow: 'scroll',
          padding: '0.25rem',
        }}
      >
        {topExtra}
        {isContent && (
          <PaperWithoutScroll
            sx={
              isUser
                ? {
                    marginRight: '0.25rem',
                    overflowX: 'auto',
                    padding: '0.25rem',
                    width: '100%',
                    ...props.sx,
                  }
                : {
                    marginLeft: '0.25rem',
                    overflowX: 'auto',
                    padding: '0.25rem',
                    width: '100%',
                    ...props.sx,
                  }
            }
          >
            <Typography component="div" p={1} variant="body2">
              {children}
            </Typography>

            {extra}
          </PaperWithoutScroll>
        )}
        {button}
      </BoxWithoutScroll>
    </Box>
  );
};
