export const htmlToMarkdown = (html: string): string =>
  html
    .replace(/<b>(.*?)<\/b>/gi, '**$1**') // Bold to Markdown
    .replace(/<strong>(.*?)<\/strong>/gi, '**$1**') // Strong to Markdown
    .replace(/<i>(.*?)<\/i>/gi, '*$1*') // Italics to Markdown
    .replace(/<em>(.*?)<\/em>/gi, '*$1*') // Emphasis to Markdown
    .replace(/<u>(.*?)<\/u>/gi, '__$1__') // Underline to Markdown
    .replace(/<h1>(.*?)<\/h1>/gi, '# $1') // H1 to Markdown
    .replace(/<h2>(.*?)<\/h2>/gi, '## $1') // H2 to Markdown
    .replace(/<h3>(.*?)<\/h3>/gi, '### $1') // H3 to Markdown
    .replace(/<a href="(.*?)">(.*?)<\/a>/gi, '[$2]($1)') // Links to Markdown
    .replace(/<li>(.*?)<\/li>/gi, '- $1') // List item to Markdown
    .replace(/<\/?ul>/gi, '') // Remove <ul> tags
    .replace(/<\/?ol>/gi, '') // Remove <ol> tags
    .replace(/<br\s*\/?>/gi, '\n') // Line breaks to Markdown
    .replace(/<p>(.*?)<\/p>/gi, '$1\n') // Paragraphs to line breaks
    .trim();

export const fixNewLine = (html: string): string =>
  html
    .replace(/\\n/g, '\n') // Replace "\n" string with actual newline
    .trim();
