// Check if the link is a reference link with this format [[1]](http://example.com)
const REF_LINK_REGEX = /^\[\d+\]$/;
export const isReferenceLabel = (label: string): boolean =>
  REF_LINK_REGEX.test(label);

const REF_LINK_ID_REGEX = /\[|\]/g;
export const getReferenceLinkId = (link: string): number =>
  parseInt(link.replace(REF_LINK_ID_REGEX, '') || '', 10);

const DOC_ID_REGEXES = [/\/documents\/([a-f0-9_]+)/, /\/pdf\/([a-f0-9_]+)/];
export const getDocumentId = (link: string) => {
  for (const regex of DOC_ID_REGEXES) {
    const match = link.match(regex);
    if (match) {
      return match[1];
    }
  }
  return null;
};

const CHUNK_ID_REGEX = /\?chunkId=([a-f0-9_]+)/;
export const getChunkId = (link: string) => {
  const match = link.match(CHUNK_ID_REGEX);
  return match ? match[1] : null;
};
