import React, { FC, ReactNode } from 'react';

import { LinkRenderer } from './LinkRenderer';
import { SimpleMarkdownText } from './SimpleMarkdownText';

interface Props {
  className?: string;
  components?: Record<string, ReactNode>;
  content: string;
}

export const MarkdownText: FC<Props> = ({ content, ...rest }) => (
  <SimpleMarkdownText
    components={{
      a: LinkRenderer,
    }}
    content={content}
    {...rest}
  />
);
