import React, { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { MarkdownText } from '../../../Markdown/MarkdownText';

import { useStyles } from './useStyles';

export interface NoteItemMarkdownProps {
  content: string;
  editable?: boolean;
}

export const NoteItemMarkdown: FC<NoteItemMarkdownProps> = ({
  content,
  editable,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const preparedContent = useMemo(
    () => content.replace(/<(\/)?b>/g, '**'),
    [content]
  );

  return (
    <Typography
      aria-label="note content"
      className={clsx(classes.content, editable && classes.editable)}
      color={!preparedContent ? 'textSecondary' : 'textPrimary'}
      component="div"
      data-testid="NoteItemMarkdown"
      variant="body2"
    >
      <MarkdownText
        content={preparedContent || t('notes.content.addNotePlaceholder')}
      />
    </Typography>
  );
};
