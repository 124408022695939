import { useEffect, useState } from 'react';

import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { listDocuments, ListDocumentsPropertyName } from 'api/searchApi';
import {
  QUERY_OPTIONS,
  REFETCH_INTERVAL,
} from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { findDocument } from 'common/utils/docIdHelpers';
import { isBaseError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectSearchResultsHits } from 'containers/SearchResults/searchResults.slice';
import { selectUser } from 'containers/User/user.slice';

import { docsQueryKeys } from '../docs.utils';

export type UseDocDetailsReturn = UseQueryResult<
  RetrievalUnitData | null,
  BaseError
>;

export type UseDocDetailsProps = {
  docId: string | null;
  options?: Omit<
    UseQueryOptions<RetrievalUnitData | null, BaseError>,
    'queryKey' | 'queryFn'
  >;
  propertyName?: ListDocumentsPropertyName;
  salt?: string;
};

export const useDocDetails = ({
  docId,
  options = {},
  propertyName = 'organize_doc_id',
  salt,
}: UseDocDetailsProps): UseDocDetailsReturn => {
  const hits = useSelector(selectSearchResultsHits);
  const parsedHostname = useParsedHostname();
  const userSettings = useSelector(selectUser);
  const [shouldPoll, setShouldPoll] = useState(true);

  const query = useQuery<RetrievalUnitData | null, BaseError>(
    docsQueryKeys.detailsItem(docId, userSettings?.indexCluster, salt),
    async () => {
      if (!docId) {
        return null;
      }
      const document = hits.find(findDocument(docId));
      if (document) {
        return document;
      }
      const { data } = await listDocuments({
        docIds: [docId],
        indexCluster: userSettings?.indexCluster,
        propertyName: propertyName,
        tenant: parsedHostname.tenant,
      });

      return data.items[0] ?? null;
    },
    {
      ...QUERY_OPTIONS,
      refetchInterval: shouldPoll ? REFETCH_INTERVAL : false,
      retry: 1,
      staleTime: Infinity,
      ...options,
    }
  );

  useEffect(() => {
    setShouldPoll(
      !!query.data &&
        !isBaseError(query.data) &&
        query.data.status?.title === 'PENDING'
    );
  }, [query.data]);

  return query;
};
