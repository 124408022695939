import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { listDocuments, ListDocumentsPropertyName } from 'api/searchApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectUser } from 'containers/User/user.slice';

export const chunkQueryKeys = {
  all: ['chunk'] as const,
  details: () => [...chunkQueryKeys.all, 'details'] as const,
  detailsItem: (
    chunkId: string | null,
    indexCluster: string | undefined,
    salt: string | undefined
  ) => [...chunkQueryKeys.details(), chunkId, indexCluster, salt] as const,
};

export type UseChunkDetailsReturn = UseQueryResult<
  RetrievalUnitData[] | null,
  BaseError
>;

export type UseChunkDetailsProps = {
  chunkId: string | null;
  options?: Omit<
    UseQueryOptions<RetrievalUnitData[] | null, BaseError>,
    'queryKey' | 'queryFn'
  >;
  propertyName?: ListDocumentsPropertyName;
  salt?: string;
};

export const useChunkDetails = ({
  chunkId,
  options = {},
  propertyName = 'id',
  salt,
}: UseChunkDetailsProps): UseChunkDetailsReturn => {
  const parsedHostname = useParsedHostname();
  const userSettings = useSelector(selectUser);
  const query = useQuery<RetrievalUnitData[] | null, BaseError>(
    chunkQueryKeys.detailsItem(chunkId, userSettings?.indexCluster, salt),
    async () => {
      if (!chunkId) {
        return null;
      }
      const { data } = await listDocuments({
        docIds: [chunkId],
        indexCluster: userSettings?.indexCluster,
        propertyName: propertyName,
        retrievalUnitValue: 'chunk',
        tenant: parsedHostname.tenant,
      });

      return data.items ?? null;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      staleTime: Infinity,
      ...options,
    }
  );

  return query;
};
