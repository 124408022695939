import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';

import { Optional } from 'common/utils/assert';
import { SimpleItem } from 'containers/SearchResults/SimpleItem/SimpleItem';

import { RetrievalUnitData } from '../../../containers/RetrievalUnit/RetrievalUnitData.interface';
import { fixNewLine } from '../../utils/markdown';

interface Props {
  content: Optional<string>;
  data?: Optional<RetrievalUnitData>;
  href?: string;
  isLoading?: Optional<boolean>;
}

export const ReferenceTooltip: FC<Props> = ({
  content,
  data,
  href,
  isLoading,
}) => {
  if (isLoading) {
    return <CircularProgress color="secondary" size={18} disableShrink />;
  }
  if (href) {
    return <SimpleItem content={content} href={href} item={data} />;
  }

  if (content) {
    return <SimpleItem content={fixNewLine(content)} href={null} item={null} />;
  }

  return null;
};
