export const docsQueryKeys = {
  all: ['docs'] as const,
  details: () => [...docsQueryKeys.all, 'details'] as const,
  detailsItem: (
    docId: string | null,
    indexCluster: string | undefined,
    salt: string | undefined
  ) => [...docsQueryKeys.details(), docId, indexCluster, salt] as const,
};

export const multipleDocsQueryKeys = {
  all: ['multi_docs'] as const,
  details: () => [...multipleDocsQueryKeys.all, 'details'] as const,
  detailsItem: (
    docsIds: string[],
    indexCluster: string | undefined,
    salt: string | undefined
  ) =>
    [...multipleDocsQueryKeys.details(), docsIds, indexCluster, salt] as const,
};
