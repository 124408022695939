import React, { FC } from 'react';

import { Popover, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ChangelogUpdatesNotification } from '../ChangelogUpdatesNotification';

import { NewRecsNotifications } from './NewRecsNotifications/NewRecsNotifications';
import { ReportsNotifications } from './ReportsNotifications/ReportsNotifications';
import { TagsNotifications } from './TagsNotifications/TagsNotifications';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    '& > * + *': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    minWidth: 300,
  },
}));

interface Props {
  anchorEl: HTMLElement | null;
  changelogUpdates: boolean;
  onClose: () => void;
}

export const NotificationsContent: FC<Props> = ({
  anchorEl,
  changelogUpdates,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      classes={{ paper: classes.paper }}
      data-testit="NotificationsContent"
      open={!!anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={onClose}
    >
      <ReportsNotifications onClose={onClose} />
      <TagsNotifications onClose={onClose} />
      <NewRecsNotifications onClose={onClose} />
      {changelogUpdates && <ChangelogUpdatesNotification onClick={onClose} />}
    </Popover>
  );
};
