import {
  QueryForm,
  QueryFormQParams,
  Results,
  SystemResult,
} from '@zarn/vendor/dist/query-logging';

import { RetrievalMethodEnum } from 'common/enums';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import {
  SearchPayload,
  SearchPayloadSort,
} from 'containers/Search/SearchPayload.interface';

import {
  ExplanationQueryLoggingResult,
  QAKQueryLoggingResult,
  QAQueryLoggingResult,
  QueryLoggingResult,
} from './queryLoggingApi.types';

export const serializeQueryLoggingFilters = (
  payload: SearchPayload
): Object => {
  const { date, docIds, docSources, docTypes, term, year } = payload;

  return {
    range: [
      ...(year
        ? [{ lower_bound: year[0], name: 'years', upper_bound: year[1] }]
        : []),
      ...(date
        ? [
            {
              lower_bound: date[0],
              name: 'timestamp',
              upper_bound: date[1],
            },
          ]
        : []),
    ],
    term: [...(term ? [{ name: term[0], value: term[1] }] : [])],
    terms: [
      ...(docIds ? [{ name: 'doc_ids', values: docIds }] : []),
      ...(docSources ? [{ name: 'source', values: docSources }] : []),
      ...(docTypes ? [{ name: 'document_type', values: docTypes }] : []),
    ],
  };
};

export const serializeQueryLoggingSort = (
  sort: SearchPayloadSort | undefined
): Object | undefined => {
  return sort
    ? {
        field: sort[0],
        order: sort[1],
      }
    : undefined;
};

export const serializeQueryFormQParams = (
  payload: SearchPayload
): QueryFormQParams => {
  return {
    extra: {
      searchEngine: payload.searchEngine,
    },
    filters: serializeQueryLoggingFilters(payload),
    nn_search: payload.retrievalMethod === RetrievalMethodEnum.Knn,
    retrieval_unit: payload.retrievalUnit,
    sort: serializeQueryLoggingSort(payload.sort),
  };
};

export const serializeSystemResult = (
  { document, title }: RetrievalUnitData,
  rank: number
): SystemResult => {
  return {
    human_readable: title,
    id: document.id,
    rank,
  };
};

export const serializeSystemResultsItem = (
  data: QueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: data.documents.map(serializeSystemResult),
  };
};

export const serializeQueryLoggingPayload = (
  payload: SearchPayload,
  results: Array<QueryLoggingResult>
): QueryForm => {
  return {
    q: payload.queryString,
    q_params: serializeQueryFormQParams(payload),

    results: results.map(serializeSystemResultsItem),
    // fake session id that we use in an old logging system to distinguish anonymous users from logged in ones
    session_id: 'aaa_bbbb_111',
  };
};

export const serializeQASystemResultsItem = (
  data: QAQueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: [
      {
        extra: {
          evidences: data.documents.map((item) => ({
            highlight: item.highlight,
            id: item.document.id,
          })),
          question: data.question,
        },
        human_readable: data.explanation ?? '',
        id: '0',
        rank: 0,
      },
    ],
  };
};

export const serializeQAKSystemResultsItem = ({
  answers,
  ids,
  system,
}: QAKQueryLoggingResult): Results => {
  return {
    system: system,
    system_results: [
      {
        extra: { ids },
        human_readable: answers,
        id: '',
        rank: 0,
      },
    ],
  };
};

export const serializeExplanationSystemResultsItem = (
  data: ExplanationQueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: [
      {
        extra: {
          context_doc_ids: data.docIds,
          passage: data.passage,
        },
        human_readable: data.explanation ?? '',
        id: '0',
        rank: 0,
      },
    ],
  };
};
