import React from 'react';

import { lighten, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { HighlightContentType } from '@zarn/pdf-viewer';

import { MarkdownText } from 'common/components/Markdown/MarkdownText';

export const useStyles = makeStyles<Theme, { color: string }>(
  (theme: Theme) => ({
    img: {
      display: 'block',
      maxWidth: '100%',
    },
    root: {
      backgroundColor: ({ color }) => lighten(color, 0.8),
      border: ({ color }) => `1px solid ${color}`,
      borderLeft: ({ color }) => `4px solid ${color}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      transition: theme.transitions.create('background-color'),
      wordBreak: 'break-word',
    },
    rootClickable: {
      '&:hover': {
        backgroundColor: ({ color }) => lighten(color, 0.6),
      },
      cursor: 'pointer',
    },
    rootImg: {
      width: 'fit-content',
    },
  })
);

export type NoteAnnotationHighlightItemProps = {
  color?: string;
  contentBody: string;
  contentType: HighlightContentType;
  onClick?: () => void;
};

const NoteAnnotationHighlightItem = ({
  color = '#ffe28f',
  contentBody,
  contentType,
  onClick,
}: NoteAnnotationHighlightItemProps) => {
  const classes = useStyles({ color });
  const isImage = contentType === 'image';

  return (
    <Typography
      aria-label="Annotation highlight"
      className={clsx(
        classes.root,
        onClick && classes.rootClickable,
        isImage && classes.rootImg
      )}
      component="div"
      role="button"
      variant="body2"
      onClick={onClick}
    >
      {!isImage && <MarkdownText content={contentBody} />}

      {isImage && (
        <img alt="highlight image" className={classes.img} src={contentBody} />
      )}
    </Typography>
  );
};

export default NoteAnnotationHighlightItem;
