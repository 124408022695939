import { CSSProperties } from 'react';

import { captureException } from '@sentry/react';
import { cloneDeep, orderBy } from 'lodash';

import {
  BibTex,
  DocumentContent,
  DocumentListHit,
  DocumentTypeString,
  GitHubRepo,
  SearchHit,
  Tweet,
} from '@zarn/vendor/dist/search';
import {
  UserDocumentResource,
  UserDocumentStatus,
} from '@zarn/vendor/dist/user-documents';

import { ERROR_BIBTEX } from 'common/constants';
import { HitType, PrivateDocAccessRoleEnum } from 'common/enums';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import {
  DocAsset,
  DocExternalPublicAsset,
  DocImage,
  DocLogo,
  DocPrivateAsset,
  DocPublicAsset,
  DocResource,
  DuplicateDocument,
  RetrievalUnitData,
  RetrievalUnitGithubRepo,
  RetrievalUnitStatus,
} from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { TweetDocument } from 'containers/RetrievalUnit/types';
import { DocCardComposition } from 'containers/User/User.enum';

import { ChunkHighlight } from '../interfaces/ChunkHighlight.interfaces';
import { HitItem } from '../interfaces/HitItem';

import { defined } from './assert';
import { mapAuthor } from './author';
import { loadDisplayConfigurationFromLocalStorage } from './displayConfiguration.utils';
import { getField } from './getField';

export const removeSuffixFromId = (id: string): string => id.split('_')[0];

export const addSuffixToDocId = (id: string): string => `${id}_0`;

export enum DocSourcesEnum {
  Default = '',
  GitHub = 'GitHub',
  ICLR = 'ICLR',
  NeurIPS = 'Advances in Neural Information Processing Systems',
  TowardsDataScience = 'Towards Data Science',
  Unknown = 'Unknown',
  arXiv = 'arXiv',
}

export const ImagesMap: Record<string, string> = {
  [DocSourcesEnum.TowardsDataScience]: '/assets/img/tds-logo.png',
  [DocSourcesEnum.ICLR]: '/assets/img/iclr-logo.png',
  [DocSourcesEnum.arXiv]: '/assets/img/arxiv-logo.png',
  [DocSourcesEnum.NeurIPS]: '/assets/img/neurips-logo.png',
  [DocSourcesEnum.Unknown]: '/assets/img/citation-source.png',
  [DocSourcesEnum.Default]: '/assets/img/no-img-source.png',
};

export const DEFAULT_IMAGE = ImagesMap[DocSourcesEnum.Default];

export const getPaperImgBySource = (source: DocSourcesEnum): string =>
  ImagesMap[source] ?? DEFAULT_IMAGE;

export const normalizePaperSource = (source: string): string => {
  switch (source) {
    case 'Towards Data Science':
      return 'TDS';
    case 'private-document':
      return 'My document';
    case 'Advances in Neural Information Processing Systems':
      return 'NeurIPS';
    case 'International Conference on Machine Learning':
      return 'ICML';
    case 'Unknown':
      return 'Citation';
    default:
      return source;
  }
};

export const mergeHighlightAndAbstract = (
  abstractContent: string,
  highlight?: string
): string => {
  if (!highlight) return abstractContent;

  return `${highlight}\n\n\n\n**Full abstract**\n\n${abstractContent}`;
};

export const getPaperTextMaxLength = (
  highlight?: string,
  cardComposition?: DocCardComposition
): number => {
  const MAX = cardComposition === DocCardComposition.Compressed ? 120 : 200;
  const highlightLength = highlight?.length || MAX;
  return highlightLength < MAX ? highlightLength : MAX;
};

export const getPaperImgDimensions = (
  { width: wrapperWidth }: DOMRect,
  { height: imgHeight, width: imgWidth }: { height: number; width: number },
  isIcon?: boolean,
  cardComposition?: DocCardComposition
): { imgStyles: CSSProperties; wrapperStyles: CSSProperties } => {
  const styles = {
    imgStyles: !isIcon ? { width: '100%' } : {},
    wrapperStyles: {},
  };
  const MAX_DELTA = cardComposition === DocCardComposition.Compressed ? 1 : 1.4;

  const delta = imgHeight / imgWidth;

  return delta < MAX_DELTA
    ? styles
    : {
        ...styles,
        wrapperStyles: { height: Math.ceil(wrapperWidth * MAX_DELTA) },
      };
};

export function getRetrievalUnitDate(
  created: string | null = null,
  documentType: string
): string | null {
  const isPrivateDoc = documentType === HitType.PrivateDocument;

  return created && !isPrivateDoc ? created : null;
}

export function deserializeDuplicateDocument(
  hit: SearchHit
): DuplicateDocument {
  // @ts-ignore
  const { _id, metadata, uri } = hit;

  return {
    document: {
      id: _id,
      query: '',
      type: 'document',
    },
    source: metadata.source ?? '',
    uri: uri ?? null,
  };
}

export function deserializeTweets(tweet: Tweet): TweetDocument {
  return {
    text: tweet.tweet_text,
    timestamp: tweet.tweet_timestamp,
    url: tweet.tweet_url,
    userName: tweet.tweet_user_name,
    userProfilePictureUrl: tweet.tweet_user_profile_picture_url || undefined,
    userProfileUrl: tweet.tweet_user_profile_url,
  };
}

export function deserializeDocResource({
  resource_type,
  resource_value,
}: UserDocumentResource): DocResource {
  return {
    resourceType: resource_type,
    resourceValue: resource_value,
  };
}

export function deserializePrivateDocSharing(
  sharingValue: string
): PrivateDocAccessRoleEnum {
  switch (sharingValue) {
    case 'own':
      return PrivateDocAccessRoleEnum.Own;
    case 'org':
      return PrivateDocAccessRoleEnum.Org;
    default:
      return PrivateDocAccessRoleEnum.Own;
  }
}

export function serializeDocResource({
  resourceType,
  resourceValue,
}: DocResource): UserDocumentResource {
  return {
    resource_type: resourceType,
    resource_value: resourceValue,
  };
}

export function deserializeGithubRepos(
  repo: GitHubRepo
): RetrievalUnitGithubRepo {
  return {
    description: repo.description,
    isOfficial: repo.mentioned_in_paper,
    repoName: repo.name,
    repoOwner: repo.owner,
    stars: repo.stars,
    url: repo.gh_url,
  };
}

export function getDocumentType(
  document_type: DocumentTypeString,
  resources?: UserDocumentResource[]
): string {
  if (!resources) {
    return document_type;
  }

  for (const { resource_type } of resources) {
    if (resource_type === 'private_doc_id') return HitType.PrivateDocument;
    if (resource_type === 'external_doc_id') return HitType.ExternalDocument;
  }

  return document_type;
}

const findResource = (
  resources: undefined | UserDocumentResource[],
  resourceType: string
) =>
  resources?.find(({ resource_type }) => resource_type === resourceType) ??
  null;

const findDocContent = (
  content: undefined | DocumentContent[],
  contentType: string
) =>
  content?.find(({ content_path }) =>
    content_path?.url_content?.url?.endsWith(contentType)
  ) ?? null;

const buildAsset = <T extends DocAsset>(
  asset: UserDocumentResource | null,
  assetType: DocAssetTypeEnum
): T | null =>
  asset
    ? ({
        assetType,
        assetValue: asset.resource_value,
      } as T)
    : null;

const getPrivateDocId = (resources?: UserDocumentResource[]): string | null => {
  const privateDoc = findResource(resources, 'private_doc_id');

  return privateDoc ? privateDoc.resource_value : null;
};

export const getDocPrivateAsset = (
  resources?: UserDocumentResource[],
  doc_content?: DocumentContent[]
): DocPrivateAsset | null => {
  const privateAsset = findResource(resources, 'private_pdf_url');
  if (!privateAsset) {
    const documentContent = findDocContent(doc_content, 'pdf_url');
    if (documentContent && documentContent.content_path.url_content?.url) {
      return {
        assetType: DocAssetTypeEnum.PrivateAsset,
        assetValue: documentContent.content_path.url_content.url,
      };
    }
  }

  return buildAsset<DocPrivateAsset>(
    privateAsset,
    DocAssetTypeEnum.PrivateAsset
  );
};

export function getDocPublicAsset(
  resources?: UserDocumentResource[],
  doc_content?: DocumentContent[]
): DocPublicAsset | null {
  const publicAsset = findResource(resources, 'pdf_url');

  const asset = buildAsset<DocPublicAsset>(
    publicAsset,
    DocAssetTypeEnum.PublicAsset
  );
  if (!asset) {
    const documentContent = findDocContent(doc_content, 'pdf_url');
    if (documentContent && documentContent.content_path.url_content?.url) {
      return {
        assetType: DocAssetTypeEnum.PublicAsset,
        assetValue: documentContent.content_path.url_content.url,
      };
    }
  }

  if (!asset) {
    return null;
  }
  // TODO: Verify this hack
  asset.assetValue = 'pdf_url';
  return asset;
}

const getDocExternalPublicAsset = (
  resources?: UserDocumentResource[],
  doc_content?: DocumentContent[]
): DocExternalPublicAsset | null => {
  const externalPublicAsset = findResource(resources, 'external_pdf_url');
  if (!externalPublicAsset) {
    const documentContent = findDocContent(doc_content, 'external_pdf_url');
    if (documentContent && documentContent.content_path.url_content?.url) {
      return {
        assetType: DocAssetTypeEnum.ExternalPublicAsset,
        assetValue: documentContent.content_path.url_content.url,
      };
    }
  }

  return buildAsset<DocExternalPublicAsset>(
    externalPublicAsset,
    DocAssetTypeEnum.ExternalPublicAsset
  );
};

const getImage = (
  resources?: UserDocumentResource[],
  doc_content?: DocumentContent[]
): DocImage | null => {
  const imageAsset = findResource(resources, 'image_url');
  if (!imageAsset) {
    const documentContent = findDocContent(doc_content, 'image_url');
    if (documentContent && documentContent.content_path.url_content?.url) {
      return {
        assetType: DocAssetTypeEnum.Image,
        assetValue: documentContent.content_path.url_content.url,
      };
    }
  }

  return buildAsset<DocImage>(imageAsset, DocAssetTypeEnum.Image);
};

const getLogo = (
  resources?: UserDocumentResource[],
  doc_content?: DocumentContent[]
): DocLogo | null => {
  const logoAsset = findResource(resources, 'logo_url');
  if (!logoAsset) {
    const documentContent = findDocContent(doc_content, 'logo_url');
    if (documentContent && documentContent.content_path.url_content?.url) {
      return {
        assetType: DocAssetTypeEnum.Logo,
        assetValue: documentContent.content_path.url_content.url,
      };
    }
  }

  return buildAsset<DocLogo>(logoAsset, DocAssetTypeEnum.Logo);
};

export const getDocStatus = ({
  status,
  status_codes,
  status_message,
}: Partial<DocumentListHit>): RetrievalUnitStatus | null => {
  if (!status) {
    return null;
  }
  const statusMessage =
    status === UserDocumentStatus.Error && status_message ? status_message : '';
  const statusCodes =
    status === UserDocumentStatus.Error && status_codes ? status_codes : [];
  return {
    codes: statusCodes as any,
    message: statusMessage,
    title: status,
  };
};

export interface TemporaryChunkHighlight {
  offset_end: number;
  offset_start: number;
  page: number;
  page_height: number;
  page_width: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const getSize = (s1: number, s2: number) => Math.abs(s1 - s2);

export const deserializeChunkHighlight = (
  chunkHighlight: TemporaryChunkHighlight
): ChunkHighlight => ({
  height: getSize(chunkHighlight.y1, chunkHighlight.y2),
  pageHeight: chunkHighlight.page_height,
  pageNumber: chunkHighlight.page,
  pageWidth: chunkHighlight.page_width,
  width: getSize(chunkHighlight.x1, chunkHighlight.x2),
  x1: chunkHighlight.x1,
  x2: chunkHighlight.x2,
  y1: chunkHighlight.y1,
  y2: chunkHighlight.y2,
});

const getCreator = (obj: object, path: string) => {
  const creator = getField(obj, path);

  if (typeof creator === 'string') {
    return [
      {
        full_name: creator,
        uid: '',
      },
    ];
  }

  if (Array.isArray(creator) && creator[0] && typeof creator[0] === 'string') {
    return creator.map((name: string) => ({
      full_name: name,
      uid: '',
    }));
  }

  return creator;
};

const mergeDynamicFields = <T extends HitItem>(item: T) => {
  if (!('custom_metadata' in item)) {
    return item;
  }

  const displayConfiguration = loadDisplayConfigurationFromLocalStorage();
  if (!displayConfiguration) {
    return item;
  }

  const copy = cloneDeep(item);

  if (!('custom_metadata' in copy)) {
    copy.custom_metadata = {};
  }

  const custom_metadata = copy.custom_metadata as any;

  if (!custom_metadata) {
    return item;
  }

  if (!('metadata' in custom_metadata)) {
    // @ts-ignore
    custom_metadata.metadata = {} as HitMetadata;
  }

  // @ts-ignore
  const metadata = custom_metadata.metadata as HitMetadata;

  if (displayConfiguration?.createdByField) {
    metadata.creator = getCreator(
      custom_metadata,
      displayConfiguration.createdByField
    );
  }

  if (displayConfiguration?.dateField) {
    metadata.created = getField(
      custom_metadata,
      displayConfiguration.dateField
    );
  }

  if (displayConfiguration?.descriptionField) {
    metadata.abstract = getField(
      custom_metadata,
      displayConfiguration.descriptionField
    );
  }

  if (displayConfiguration?.titleField) {
    metadata.title = getField(custom_metadata, displayConfiguration.titleField);
  }

  if (displayConfiguration?.sourceField) {
    metadata.source = getField(
      custom_metadata,
      displayConfiguration.sourceField
    );
  }

  if (displayConfiguration?.urlField) {
    // @ts-ignore
    copy.uri = getField(custom_metadata, displayConfiguration.urlField);
  }

  if (displayConfiguration?.boundingBoxesField) {
    let boundingBoxes = getField(
      custom_metadata,
      displayConfiguration.boundingBoxesField
    );
    if (Array.isArray(boundingBoxes)) {
      boundingBoxes = boundingBoxes.filter((box: any) => box !== undefined);
    }
    if (
      boundingBoxes &&
      boundingBoxes.length > 0 &&
      !Array.isArray(boundingBoxes[0]) &&
      copy.custom_metadata
    ) {
      copy.custom_metadata = {
        ...copy.custom_metadata,
        representations: {
          ...(getField(copy.custom_metadata, 'representations') || {}),
          chunk_bounding_boxes: boundingBoxes,
        },
      };
    }
  }

  return copy;
};

export const isDocumentListHit = (item: HitItem): item is DocumentListHit =>
  (item as DocumentListHit).document_id !== undefined ||
  (item as DocumentListHit).status !== undefined;

export const deserializeSearchHit = <T extends HitItem>(
  item: T
): RetrievalUnitData => {
  const mergedItem = mergeDynamicFields(item);

  const {
    _id,
    document_content,
    document_id,
    get_bibtex_id,
    get_cites_id,
    get_refs_id,
    get_similar_docs_id,
    highlight,
    highlight_tokens: highlightTokens,
    organize_doc_id,
    share_uri,
    uri,
    uri_hash,
  } = mergedItem as any;
  const custom_metadata = defined(
    mergedItem.custom_metadata,
    'custom_metadata of SearchHitExtended'
  );

  const {
    document_type,
    duplicates,
    github_repos,
    github_score,
    metadata,
    no_citations,
    no_references,
    representations,
    tweets,
    twitter_popularity_score,
    uid,
  } = custom_metadata as any;

  // @ts-ignore
  const resources = mergedItem.resources ?? custom_metadata.resources ?? null;

  const documentType = getDocumentType(document_type, resources);
  const privateAsset = getDocPrivateAsset(resources, document_content);
  const privateDocId = getPrivateDocId(resources);
  const publicAsset = getDocPublicAsset(resources, document_content);
  const externalPublicAsset = getDocExternalPublicAsset(
    resources,
    document_content
  );
  const status = isDocumentListHit(mergedItem)
    ? getDocStatus(mergedItem)
    : undefined;
  const image = getImage(resources, document_content);
  const logo = getLogo(resources, document_content);
  const chunkBoundingBoxes = representations?.chunk_bounding_boxes ?? [];
  if (representations?.chunk_bounding_boxes) {
    delete representations.chunk_bounding_boxes;
  }

  return {
    abstractContent: metadata?.abstract ?? '',
    authors: metadata?.creator?.map(mapAuthor),
    date: getRetrievalUnitDate(metadata?.created, documentType),
    document: {
      id: _id,
      query: '',
      type: documentType,
    },
    documentId: document_id ?? removeSuffixFromId(_id),
    duplicates: (duplicates ?? []).map(deserializeDuplicateDocument),
    githubRepos: orderBy(
      (github_repos ?? []).map(deserializeGithubRepos),
      ['isOfficial', 'stars'],
      ['desc', 'desc']
    ),
    githubScore: github_score,
    highlight,
    numberOfCitations: no_citations,
    numberOfRefs: no_references,
    numberOfTweets: twitter_popularity_score,
    ontologyId: uid,
    representations: {
      chunkHighlights: chunkBoundingBoxes.map(deserializeChunkHighlight),
      text: '',
      ...representations,
    },
    resources: resources?.map(deserializeDocResource),
    source: (metadata?.source as DocSourcesEnum) ?? DocSourcesEnum.Default,
    title: metadata?.title ?? '',
    tweets: (tweets ?? []).map(deserializeTweets),
    uri: uri ?? null,
    year: metadata?.date,
    ...(highlightTokens ? { highlightTokens } : {}),
    ...(privateAsset ? { privateAsset } : {}),
    ...(publicAsset ? { publicAsset } : {}),
    ...(externalPublicAsset ? { externalPublicAsset } : {}),
    ...(privateDocId ? { privateDocId } : {}),
    ...(status ? { status } : {}),
    ...(image ? { image } : {}),
    ...(logo ? { logo } : {}),
    getBibtexId: get_bibtex_id,
    getCitesId: get_cites_id,
    getRefsId: get_refs_id,
    getSimilarDocsId: get_similar_docs_id,
    organizeDocId: uri_hash || organize_doc_id,
    shareUri: share_uri,
    ...(metadata?.identifier ? { identifier: metadata.identifier } : {}),
    ...(mergedItem?.sharing
      ? {
          sharing: mergedItem.sharing.map(deserializePrivateDocSharing),
        }
      : {}),
    ...(item?.owner_uuid ? { ownerUuid: item.owner_uuid } : {}),
  };
};

export const deserializeBibtexStr = (bibtex: string): string => {
  try {
    return decodeURIComponent(JSON.parse(bibtex).replace(/\\%/g, '%'));
  } catch (error) {
    captureException(error);
    return ERROR_BIBTEX;
  }
};

export const deserializeBibtexList = (bibtexList: BibTex[]): string =>
  bibtexList.map(({ value }) => deserializeBibtexStr(value)).join('\n');

export const getDocIdWithoutChunk = (id: string | undefined) => {
  if (!id) {
    return;
  }

  return id.split('_')[0];
};
