import React from 'react';

import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import { clearLabelFromBrackets } from 'common/utils/strings.utils';
import { useChunkDetails } from 'containers/Chat/DocsContext/hooks/useChunkDetails';
import { useDocDetails } from 'containers/Docs/hooks/useDocDetails';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { ReferenceTooltip } from './ReferenceTooltip';
import { TransparentTooltip } from './TransparentTooltip';
import { getChunkId, getDocumentId } from './utils';

const useStyles = makeStyles({
  colorReferenceLink: {
    textDecoration: 'none',
  },
});

interface Props {
  href: string;
  label: number | string;
  title?: string;
}

const isExternalDoc = (docData?: RetrievalUnitData | null) =>
  docData?.resources?.some(
    (resource) => resource.resourceType === 'external_doc_id'
  );

const hasPdf = (docData?: RetrievalUnitData | null) =>
  docData?.resources?.some((resource) => resource.resourceType === 'pdf_url');

export const ReferenceLink: React.FC<Props> = ({ href, label, title }) => {
  const classes = useStyles();

  const text = clearLabelFromBrackets(`${label}`);

  const location = useLocation();
  let docId = null;
  let chunkId = null;
  if (href.includes('documents/chunk')) {
    const urlParams = new URLSearchParams(href);
    chunkId = urlParams.get('property_values');
    docId = chunkId?.split('_')[0] + '_0';
  } else if (href.includes('documents/document')) {
    const urlParams = new URLSearchParams(href);
    docId = urlParams.get('property_values');
  } else {
    docId = getDocumentId(href);
    chunkId = getChunkId(href);
  }
  const { data: docData, isLoading: isDocLoading } = useDocDetails({
    docId: String(docId),
    options: {
      enabled: !!docId,
    },
    propertyName: 'id',
  });

  const { data: chunksData } = useChunkDetails({
    chunkId: chunkId ?? null,
    options: {
      enabled: !!chunkId,
    },
  });
  const chunkData = chunksData?.[0];
  let link = href;
  if (isExternalDoc(docData) && docData?.uri) {
    link = docData.uri;
  } else if (hasPdf(docData)) {
    link = `/pdf/${docId}`;
    if (chunkData) {
      link += `?chunkId=${chunkId}`;
    }
  } else if (href.startsWith('/documents/')) {
    link = `/documents/${docId}`;
  }
  const isPdfOpen = !!docId && location.pathname.includes('pdf/' + docId);
  if (isPdfOpen) {
    link = '';
  }

  const chipsProps = isPdfOpen
    ? {
        clickable: false,
      }
    : {
        clickable: true,
        component: 'a',
        href: link,
        rel: 'noopener noreferrer',
        target: '_blank',
      };

  const tooltipProps = isPdfOpen ? {} : { data: docData, isDocLoading };

  return (
    <TransparentTooltip
      title={<ReferenceTooltip content={title} href={link} {...tooltipProps} />}
    >
      <Chip
        className={classes.colorReferenceLink}
        label={text}
        size="small"
        sx={{ ml: 0.1, mr: 0.1 }}
        {...chipsProps}
      />
    </TransparentTooltip>
  );
};
