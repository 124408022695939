import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { WithTrackedActionProps } from 'common/components/TrackedActions/withTrackedAction';
import { ConversationList } from 'containers/Chat/Conversation/ConversationList';
import { useNoteConversation } from 'containers/Chat/hooks/useConversation/useNoteConversation';

import { fixNewLine } from '../../../utils/markdown';
import ButtonLink from '../../Buttons/ButtonLink';
import { MarkdownText } from '../../Markdown/MarkdownText';

export const useStyles = makeStyles((theme: Theme) => ({
  collapseButton: {
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'inherit',
  },
}));

type Props = {
  collapseByDefault?: boolean;
  contentClassname?: string;
  expandable?: boolean;
  maxTextLength: number;
  onCollapse?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any;
  renderInHTML?: boolean;
  renderMarkdown?: boolean;
  text: string;
} & WithTrackedActionProps;

export const TruncatedContent = React.memo(
  ({
    collapseByDefault = true,
    contentClassname,
    eventName,
    eventProps,
    expandable = true,
    maxTextLength,
    onCollapse,
    renderInHTML,
    renderMarkdown,
    text,
  }: Props) => {
    const { t } = useTranslation('common');
    const { collapseButton } = useStyles();
    const [collapsed, setCollapsed] = useState(collapseByDefault);

    const shouldBeTruncated = React.useMemo(
      () => maxTextLength <= text.length,
      [text, maxTextLength]
    );

    const truncatedText = React.useMemo(() => {
      return shouldBeTruncated ? `${text.slice(0, maxTextLength)} ...` : text;
    }, [text, maxTextLength, shouldBeTruncated]);

    const toggleCollapse = (
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      setCollapsed(!collapsed);
      if (onCollapse && collapsed) {
        onCollapse(e);
      }
    };

    const { conversation, isChatConversation } = useNoteConversation(text);

    const content = React.useMemo(() => {
      if (isChatConversation && conversation) {
        return <ConversationList messages={conversation.messages} />;
      }

      const toRender = fixNewLine(collapsed ? truncatedText : text);

      if (renderInHTML) {
        // eslint-disable-next-line react/no-danger
        return (
          <span
            className={contentClassname}
            dangerouslySetInnerHTML={{ __html: toRender }}
          />
        );
      }
      if (renderMarkdown) {
        return <MarkdownText className={contentClassname} content={toRender} />;
      }
      return toRender;
    }, [
      truncatedText,
      contentClassname,
      text,
      renderInHTML,
      renderMarkdown,
      collapsed,
      isChatConversation,
      conversation,
    ]);

    return (
      <Box>
        <Box sx={{ maxHeight: collapsed ? 300 : 'auto', overflow: 'auto' }}>
          {content}
        </Box>
        {shouldBeTruncated && expandable && (
          <ButtonLink
            className={collapseButton}
            eventName={eventName}
            eventProps={eventProps}
            underline="hover"
            onClick={toggleCollapse}
          >
            {collapsed ? t('buttons.more') : t('buttons.less')}
          </ButtonLink>
        )}
      </Box>
    );
  }
);

TruncatedContent.displayName = 'TruncatedContent';
